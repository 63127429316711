import axios from 'axios';

const API_BASE_URL = 'https://solarsim.com.tr/backend/public/api.php';

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const submitQuote = async (quoteData: any) => {
  try {
    const response = await api.post('?action=submit_quote', quoteData);
    return response.data;
  } catch (error) {
    console.error('Error submitting quote:', error);
    throw error;
  }
};

export const getQuotes = async () => {
  try {
    const response = await api.get('?action=get_quotes');
    return response.data;
  } catch (error) {
    console.error('Error fetching quotes:', error);
    throw error;
  }
};

export const updateQuoteStatus = async (quoteId: number, newStatus: string) => {
  try {
    const response = await api.post('?action=update_quote_status', { quoteId, status: newStatus });
    if (response.data && response.data.status === 'success') {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Invalid response structure from server');
    }
  } catch (error) {
    console.error('Error updating quote status:', error);
    throw error;
  }
};

export const registerUser = async (userData: any) => {
  const response = await api.post('?action=register_user', userData);
  return response.data;
};

export const loginUser = async (credentials: { username: string; password: string }) => {
  try {
    const response = await api.post('?action=login', credentials);
    console.log('Login response:', response.data);
    if (response.data && response.data.status === 'success') {
      if (response.data.user && response.data.user.id) {
        localStorage.setItem('authToken', response.data.user.id.toString());
        return response.data;
      } else {
        throw new Error('Invalid user data in response');
      }
    } else {
      throw new Error(response.data?.message || 'Login failed');
    }
  } catch (error: unknown) {
    console.error('Login error:', error);
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('An unexpected error occurred during login');
  }
};

export const saveRooftop = async (rooftopData: {
  area: number;
  monthlyCost: number;
  roofCoverage: string;
  geoJSON: string;
  panelType: string;
  coordinates: string;
}) => {
  try {
    const response = await api.post('?action=save_rooftop', rooftopData);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const getRooftopSummary = async (rooftopId: number) => {
  const response = await api.get(`?action=get_rooftop_summary&id=${rooftopId}`);
  return response.data;
};

export const getUsers = async () => {
  const response = await api.get('?action=get_users');
  return response.data;
};

export const updateUser = async (userId: number, userData: any) => {
  const response = await api.put(`?action=update_user&id=${userId}`, userData);
  return response.data;
};

export const deleteUser = async (userId: number) => {
  const response = await api.delete(`?action=delete_user&id=${userId}`);
  return response.data;
};

export const getSystemConfig = async () => {
  const response = await api.get('?action=get_config');
  return response.data;
};

export const updateSystemConfig = async (configData: any) => {
  const response = await api.post('?action=update_config', configData);
  return response.data;
};

export const getVisitorData = async () => {
  try {
    const response = await api.get('?action=get_visitor_data');
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const getLeads = async () => {
  try {
    const response = await api.get('?action=get_leads');
    console.log('API response:', response);
    return response.data;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const updateLeadStatus = async (leadId: number, status: string) => {
  const response = await api.post('?action=update_lead_status', { leadId, status });
  return response.data;
};

export const updateUserDarkModePreference = async (darkModePreference: boolean) => {
  const response = await api.post('?action=update_dark_mode_preference', { darkModePreference });
  return response.data;
};

export const getDarkModePreference = async () => {
  const response = await api.get('?action=get_dark_mode_preference');
  return response.data;
};

export const deleteVisitorHistory = async () => {
  try {
    const response = await api.post('?action=delete_visitor_history');
    return response.data;
  } catch (error) {
    console.error('Error deleting visitor history:', error);
    throw error;
  }
};

export const getSimulationSetting = async (name: string) => {
  try {
    const response = await api.get(`?action=get_simulation_setting&name=${name}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching simulation setting:', error);
    throw error;
  }
};

export const updateSimulationSetting = async (name: string, value: string) => {
  const response = await api.post('?action=update_simulation_setting', { name, value });
  return response.data;
};

export const getRooftops = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get_rooftops`);
    return response.data;
  } catch (error) {
    console.error('Error fetching rooftops:', error);
    throw error;
  }
};

export const deleteQuoteHistory = async () => {
  try {
    const response = await api.post('?action=delete_quote_history');
    return response.data;
  } catch (error) {
    console.error('Error deleting quote history:', error);
    throw error;
  }
};

export const getAdmins = async () => {
  const response = await api.get('?action=get_admins');
  return response.data;
};

export const registerAdmin = async (adminData: any) => {
  const response = await api.post('?action=register_admin', adminData);
  return response.data;
};

export const deleteAdmin = async (adminId: number) => {
  const response = await api.delete(`?action=delete_admin&id=${adminId}`);
  return response.data;
};

export const getPanels = async () => {
  const response = await api.get('?action=get_panels');
  return response.data;
};

export const createPanel = async (panelData: any) => {
  const response = await api.post('?action=create_panel', panelData);
  return response.data;
};

export const updatePanel = async (panelData: any) => {
  const response = await api.put('?action=update_panel', panelData);
  return response.data;
};

export const deletePanel = async (id: number) => {
  const response = await api.delete(`?action=delete_panel&id=${id}`);
  return response.data;
};

export const getEmailConfig = async () => {
  const response = await api.get('?action=get_email_config');
  return response.data;
};

export const updateEmailConfig = async (config: any) => {
  const response = await api.post('?action=update_email_config', { config });
  return response.data;
};

export const getEmailTemplates = async () => {
  const response = await api.get('?action=get_email_templates');
  return response.data;
};

export const updateEmailTemplate = async (template: any) => {
  const response = await api.post('?action=update_email_template', { template });
  return response.data;
};

export const subscribeToNewsletter = async (email: string) => {
  const response = await api.post('?action=subscribe', { email });
  return response.data;
};

export const getNewsletterSubscribers = async () => {
  const response = await api.get('?action=get_subscribers');
  return response.data;
};

export const exportQuotesToExcel = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api.php?action=export_quotes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error exporting quotes:', error);
    throw error;
  }
};

export const exportSubscribersToExcel = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api.php?action=export_subscribers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error exporting subscribers:', error);
        throw error;
    }
};