import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Button } from "../../components/ui/button";
import { ArrowLeft } from 'lucide-react';
import { getPanels } from '../../services/api';
import Spinner from "../../components/ui/Spinner";

interface PanelType {
  id: number;
  type: string;
  name: string;
  wattage: number;
  efficiency: number;
  cost_multiplier: number;
  image_url: string;
  description: string;
}

interface Suggestion {
  display_name: string;
  lat: string;
  lon: string;
}

interface SecondSectionProps {
  onBack: () => void;
  onContinue: (selectedPanelType: string) => void;
  setMapCenter: (center: { lat: number; lng: number }) => void;
  searchAddress: string;
  setSearchAddress: (address: string) => void;
  suggestions: Suggestion[];
  setSuggestions: (suggestions: Suggestion[]) => void;
  handleSearch: () => void;
  handleSuggestions: (query: string) => void;
  handleFindMe: () => void;
  handleSelectSuggestion: (suggestion: Suggestion) => void;
  showSuggestionsInPanel: boolean;
  setShowSuggestionsInPanel: (show: boolean) => void;
}

const SecondSection: React.FC<SecondSectionProps> = ({
  onBack,
  onContinue,
  setMapCenter,
  searchAddress,
  setSearchAddress,
  suggestions,
  setSuggestions,
  handleSearch,
  handleSuggestions,
  handleFindMe,
  handleSelectSuggestion,
  showSuggestionsInPanel,
  setShowSuggestionsInPanel,
}) => {
  const [panels, setPanels] = useState<PanelType[]>([]);
  const [selectedPanel, setSelectedPanel] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPanels = async () => {
      try {
        setLoading(true);
        const response = await getPanels();
        if (response.status === 'success') {
          setPanels(response.panels);
          // Set the first panel as default selected if available
          if (response.panels.length > 0) {
            setSelectedPanel(response.panels[0].type);
          }
        } else {
          setError('Panel verileri alınamadı');
        }
      } catch (error) {
        console.error('Error fetching panels:', error);
        setError('Panel verileri alınamadı');
      } finally {
        setLoading(false);
      }
    };

    fetchPanels();
  }, []);

  useEffect(() => {
    console.log('SecondSection - Current suggestions:', suggestions);
  }, [suggestions]);

  useEffect(() => {
    console.log('SecondSection received suggestions:', suggestions);
  }, [suggestions]);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    console.log('Address input changed:', value);
    setSearchAddress(value);
    handleSuggestions(value);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Spinner size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-64 space-y-4">
        <p className="text-red-500">{error}</p>
        <Button onClick={onBack} variant="outline">
          <ArrowLeft className="w-4 h-4 mr-2" />
          Geri Dön
        </Button>
      </div>
    );
  }

  const containerVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0, 
      x: -50,
      transition: { duration: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="space-y-4"
    >
      <div className="flex items-center space-x-2">
        <Button 
          variant="ghost" 
          size="sm"
          onClick={onBack}
          className="hover:bg-accent transition-colors"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Geri
        </Button>
      </div>

      <div className="space-y-4">
        {panels.map((panel) => (
          <motion.div
            key={panel.id}
            variants={itemVariants}
            className={`relative p-4 rounded-lg border transition-all duration-200 cursor-pointer
              ${selectedPanel === panel.type 
                ? 'border-primary bg-primary/5 shadow-sm' 
                : 'border-border hover:border-primary/50 hover:bg-accent/50'
              }`}
            onClick={() => setSelectedPanel(panel.type)}
          >
            <div className="flex items-start space-x-4">
              <div className="w-24 h-24 rounded-md overflow-hidden flex-shrink-0">
                <img 
                  src={panel.image_url} 
                  alt={panel.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-1 space-y-2">
                <h3 className="font-semibold">{panel.name}</h3>
                <p className="text-sm text-muted-foreground">{panel.description}</p>
                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div>
                    <span className="text-muted-foreground">Güç: </span>
                    <span className="font-medium">{panel.wattage}W</span>
                  </div>
                  <div>
                    <span className="text-muted-foreground">Verimlilik: </span>
                    <span className="font-medium">%{panel.efficiency}</span>
                  </div>
                </div>
              </div>
            </div>
            {selectedPanel === panel.type && (
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="absolute top-2 right-2 w-6 h-6 bg-primary text-primary-foreground rounded-full flex items-center justify-center"
              >
                <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </motion.div>
            )}
          </motion.div>
        ))}
      </div>

      <Button
        className="w-full mt-4"
        onClick={() => onContinue(selectedPanel)}
        disabled={!selectedPanel}
      >
        Devam Et
      </Button>
    </motion.div>
  );
};

export default SecondSection;