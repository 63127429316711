import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '../components/ui/Table';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { getAdmins, registerAdmin, deleteAdmin } from '../services/api';

interface Admin {
  id: number;
  username: string;
  email: string;
}

const AdminManagement: React.FC = () => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showAddAdminForm, setShowAddAdminForm] = useState(false);
  const [newAdmin, setNewAdmin] = useState({ username: '', email: '', password: '' });

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      setLoading(true);
      const data = await getAdmins();
      if (data.status === 'success') {
        setAdmins(data.admins);
      } else {
        throw new Error(data.message || 'Failed to fetch admins');
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleAddAdmin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const data = await registerAdmin(newAdmin);
      if (data.status === 'success') {
        setAdmins([...admins, { id: data.adminId, username: newAdmin.username, email: newAdmin.email }]);
        setShowAddAdminForm(false);
        setNewAdmin({ username: '', email: '', password: '' });
      } else {
        throw new Error(data.message || 'Failed to add admin');
      }
    } catch (error) {
      console.error('Error adding admin:', error);
      alert('Failed to add admin');
    }
  };

  const handleDeleteAdmin = async (adminId: number) => {
    if (window.confirm('Are you sure you want to delete this admin?')) {
      try {
        const data = await deleteAdmin(adminId);
        if (data.status === 'success') {
          setAdmins(admins.filter(admin => admin.id !== adminId));
        } else {
          throw new Error(data.message || 'Failed to delete admin');
        }
      } catch (error) {
        console.error('Error deleting admin:', error);
        alert('Failed to delete admin');
      }
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Admin Management</CardTitle>
      </CardHeader>
      <CardContent>
        <Button onClick={() => setShowAddAdminForm(true)} className="mb-4">Add Admin</Button>
        <Table>
          <Thead>
            <Tr>
              <Th>Username</Th>
              <Th>Email</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {admins.map((admin) => (
              <Tr key={admin.id}>
                <Td>{admin.username}</Td>
                <Td>{admin.email}</Td>
                <Td>
                  <Button onClick={() => handleDeleteAdmin(admin.id)} variant="destructive">Delete</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {showAddAdminForm && (
          <form onSubmit={handleAddAdmin} className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Add New Admin</h3>
            <div className="space-y-4">
              <div>
                <Label htmlFor="username">Username</Label>
                <Input
                  id="username"
                  value={newAdmin.username}
                  onChange={(e) => setNewAdmin({ ...newAdmin, username: e.target.value })}
                />
              </div>
              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={newAdmin.email}
                  onChange={(e) => setNewAdmin({ ...newAdmin, email: e.target.value })}
                />
              </div>
              <div>
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={newAdmin.password}
                  onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })}
                />
              </div>
            </div>
            <Button type="submit" className="mt-4">Add Admin</Button>
          </form>
        )}
      </CardContent>
    </Card>
  );
};

export default AdminManagement;