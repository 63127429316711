import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, FeatureGroup, Polygon } from 'react-leaflet';
import * as turf from '@turf/turf';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Locate } from 'lucide-react';
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import { motion, AnimatePresence } from 'framer-motion';
import { Sun, Home } from 'lucide-react';
import { saveRooftop, getSystemConfig, subscribeToNewsletter } from '../../services/api';
import Navigation from '../../components/Navigation';
import { HelpCircle } from "lucide-react";
import { convertToLatLngExpression } from '../../utils/mapUtils';
import { Phone, Mail, Facebook, Twitter, Instagram, Send } from 'lucide-react';
import { Input } from "../../components/ui/input";
import { toast } from 'sonner';
import MobileMenu from '../../components/MobileMenu';
import RoofSelectionTool from './RoofSelectionTool';
import SecondSection from './SecondSection';
import EnhancedTutorial from '../../components/tutorials/RooftopSelectionTutorial';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";

interface Suggestion {
  display_name: string;
  lat: string;
  lon: string;
}

const MainPage: React.FC = () => {
  const navigate = useNavigate();
  const mapRef = useRef<L.Map | null>(null);
  const featureGroupRef = useRef<L.FeatureGroup | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedArea, setSelectedArea] = useState<any>(null);
  const [coordinates, setCoordinates] = useState<number[][]>([]);
  const [areaInSqM, setAreaInSqM] = useState<number | null>(null);
  const [monthlyCost, setMonthlyCost] = useState('');
  const [roofCoverage, setRoofCoverage] = useState<'full' | 'partial'>('full');
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 38.4192, lng: 27.1287 });
  const [searchAddress, setSearchAddress] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [showTutorial, setShowTutorial] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
  const [config, setConfig] = useState<any>(null);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [isSubscribing, setIsSubscribing] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const [showSuggestionsInPanel, setShowSuggestionsInPanel] = useState(false);

  const fetchOptions = {
    headers: {
      'Accept': 'application/json',
      'User-Agent': 'Your App Name/1.0'
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await getSystemConfig();
        if (response.status === 'success') {
          setConfig(response.config);
        }
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []);

  const handleDrawingChange = (drawing: boolean) => {
    setIsDrawing(drawing);
    if (drawing && isMobile) {
      setIsMobileMenuOpen(false);
    }
  };

  const handlePolygonCreated = async (polygon: L.Polygon) => {
    const geoJSON = polygon.toGeoJSON();
    const coords = geoJSON.geometry.coordinates[0] as number[][];
    setSelectedArea(geoJSON);
    setCoordinates(coords);
    const area = turf.area(geoJSON);
    setAreaInSqM(Math.round(area * 100) / 100);
  };

  const handleResetSelectedArea = () => {
    setSelectedArea(null);
    setCoordinates([]);
    setAreaInSqM(null);
  };

  const handleNextClick = async (selectedPanelType: string) => {
    try {
      const response = await saveRooftop({
        coordinates: JSON.stringify(coordinates),
        area: areaInSqM || 0,
        monthlyCost: parseFloat(monthlyCost),
        roofCoverage: roofCoverage,
        panelType: selectedPanelType,
        geoJSON: JSON.stringify(selectedArea)
      });

      if (response.status === 'success' && response.rooftop_id) {
        navigate(`/summary/${response.rooftop_id}`);
      } else {
        toast.error('Çatı kaydedilirken bir hata oluştu');
      }
    } catch (error) {
      console.error('Error saving rooftop:', error);
      toast.error('Çatı kaydedilirken bir hata oluştu');
    }
  };

  const handleSearch = async () => {
    if (!searchAddress.trim()) return;
    
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?` + 
        new URLSearchParams({
          format: 'json',
          q: searchAddress,
          countrycodes: 'tr',
          limit: '1'
        }),
        fetchOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data && data.length > 0) {
        const newCenter = {
          lat: parseFloat(data[0].lat),
          lng: parseFloat(data[0].lon)
        };
        setMapCenter(newCenter);
        if (mapRef.current) {
          mapRef.current.setView([newCenter.lat, newCenter.lng], 19);
        }
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error searching address:', error);
      toast.error('Adres araması sırasında bir hata oluştu');
    }
  };

  const handleSuggestions = (query: string) => {
    console.log('Fetching suggestions for:', query);

    if (query.length < 3) {
      setSuggestions([]);
      return;
    }

    fetch(
      `https://nominatim.openstreetmap.org/search?` + 
      new URLSearchParams({
        format: 'json',
        q: query,
        countrycodes: 'tr',
        limit: '5'
      })
    )
    .then(response => response.json())
    .then(data => {
      console.log('Received suggestions:', data);

      if (data && data.length > 0) {
        const formattedSuggestions = data.map((item: any) => ({
          display_name: item.display_name,
          lat: item.lat,
          lon: item.lon
        }));
        console.log('Setting suggestions:', formattedSuggestions);
        setSuggestions(formattedSuggestions);
        setShowSuggestionsInPanel(true);
      } else {
        setSuggestions([]);
      }
    })
    .catch(error => {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    });
  };

  const handleSelectSuggestion = (suggestion: Suggestion) => {
    setSearchAddress(suggestion.display_name);
    const newCenter = {
      lat: parseFloat(suggestion.lat),
      lng: parseFloat(suggestion.lon)
    };
    setMapCenter(newCenter);
    if (mapRef.current) {
      mapRef.current.setView([newCenter.lat, newCenter.lng], 19);
    }
    setSuggestions([]);
  };

  const handleFindMe = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        if (mapRef.current) {
          mapRef.current.setView([center.lat, center.lng], 19);
        }
      },
      (error) => {
        console.error('Error getting location:', error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  };

  const handleShowTutorial = () => {
    setShowTutorial(true);
    if (isMobile) {
      setIsMobileMenuOpen(false);
    }
  };

  const handleFooterLinkClick = async (slug: string, e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_page&slug=${slug}`);
      const data = await response.json();
      
      if (data.status === 'success' && data.redirect) {
        window.location.href = data.redirect_url;
      } else {
        window.location.href = `/${slug}`;
      }
    } catch (error) {
      console.error('Error:', error);
      window.location.href = `/${slug}`;
    }
  };

  const handleNewsletterSubmit = async () => {
    if (!newsletterEmail) {
      toast.error('Lütfen e-posta adresinizi girin');
      return;
    }

    setIsSubscribing(true);
    try {
      const response = await subscribeToNewsletter(newsletterEmail);
      if (response.status === 'success') {
        toast.success('Bülten kaydınız başarıyla tamamlandı');
        setNewsletterEmail('');
      } else {
        toast.error('Bülten kaydı sırasında bir hata oluştu');
      }
    } catch (error) {
      toast.error('Bülten kaydı sırasında bir hata oluştu');
    } finally {
      setIsSubscribing(false);
    }
  };

  if (!config) {
    return <div className="h-screen w-screen flex items-center justify-center">
      <span className="animate-spin">⌛</span>
    </div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navigation />
      
      {isMobile && (
        <MobileMenu 
          config={config}
          onFooterLinkClick={handleFooterLinkClick}
        />
      )}
      
      <main className={`flex-1 relative ${isDrawing ? 'cursor-crosshair' : ''} pt-16`}>
        <div className="absolute inset-0">
          <MapContainer
            center={mapCenter}
            zoom={13}
            className="h-full w-full"
            ref={(map) => (mapRef.current = map)}
            zoomControl={false}
          >
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
            />
            <FeatureGroup ref={featureGroupRef}>
              {coordinates.length > 0 && (
                <Polygon
                  positions={convertToLatLngExpression(coordinates)}
                  pathOptions={{ color: '#3388ff', weight: 2, opacity: 0.7, fillOpacity: 0.2 }}
                />
              )}
            </FeatureGroup>
          </MapContainer>

          <RoofSelectionTool
            onDrawingChange={handleDrawingChange}
            featureGroupRef={featureGroupRef}
            mapRef={mapRef}
            onPolygonCreated={handlePolygonCreated}
            onReset={handleResetSelectedArea}
            isMobile={isMobile}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />

          {/* Help Button */}
          <div className={`
            absolute z-[1000] 
            ${isMobile 
              ? 'top-20 right-4' 
              : 'bottom-4 left-4'
            }`}
          >
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size={isMobile ? "icon" : "lg"}
                    className="bg-background/80 backdrop-blur-sm shadow-lg hover:bg-background/90 transition-all duration-200"
                    onClick={handleShowTutorial}
                  >
                    {isMobile ? (
                      <HelpCircle className="h-5 w-5" />
                    ) : (
                      <>
                        <HelpCircle className="h-5 w-5 mr-2" />
                        <span>Nasıl Kullanılır?</span>
                      </>
                    )}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Adım adım kullanım kılavuzu</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <AnimatePresence>
            {showTutorial && (
              <EnhancedTutorial
                onClose={() => setShowTutorial(false)}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
              />
            )}
          </AnimatePresence>
        </div>

        {/* Aside Panel */}
        <aside className={`
          fixed transition-all duration-300 ease-in-out
          ${isMobile 
            ? `bottom-0 left-0 right-0 w-full bg-background/95 backdrop-blur-sm rounded-t-3xl 
               shadow-lg p-4 space-y-3 overflow-y-auto z-[1001] 
               ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'}`
            : 'top-24 right-4 w-96 bg-background/80 backdrop-blur-sm rounded-lg shadow-lg p-4 space-y-4 max-h-[calc(100vh-8rem)] overflow-y-auto z-[1001]'
          }`}
        >
          {!showSecondSection ? (
            <>
              {/* First Section Content */}
              {isMobile && (
                <div className="w-12 h-1 bg-muted rounded-full mx-auto mb-4" />
              )}
              <div className="flex flex-col gap-2">
                <h2 className="text-base font-semibold">Adres Arama</h2>
                <div className="relative">
                  <div className={`flex ${isMobile ? 'flex-col gap-2' : 'flex-row'}`}>
                    <input
                      type="text"
                      placeholder="Adresi girin"
                      className={`flex-1 p-2 border rounded bg-background text-foreground
                        ${isMobile ? 'w-full rounded-lg' : 'rounded-l'}`}
                      value={searchAddress}
                      onChange={(e) => {
                        setSearchAddress(e.target.value);
                        handleSuggestions(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                    />
                    <Button
                      onClick={handleFindMe}
                      className={`bg-primary text-primary-foreground
                        ${isMobile ? 'w-full rounded-lg' : 'rounded-r'}`}
                      data-tutorial="find-me-button"
                    >
                      <Locate className="mr-2 h-4 w-4" />
                      Beni Bul
                    </Button>
                  </div>
                  
                  {Array.isArray(suggestions) && suggestions.length > 0 && (
                    <div 
                      className="absolute top-full left-0 right-0 mt-1 bg-background rounded-lg shadow-lg border z-[9999]"
                      style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        width: '100%'
                      }}
                    >
                      {suggestions.map((suggestion: Suggestion, index: number) => (
                        <button
                          key={index}
                          className="w-full text-left px-4 py-2 hover:bg-accent/50 transition-colors text-foreground"
                          onClick={() => {
                            handleSelectSuggestion(suggestion);
                            setSuggestions([]);
                          }}
                          type="button"
                        >
                          {suggestion.display_name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <AnimatePresence mode="wait">
                {areaInSqM !== null && (
                  <motion.div
                    key="selected-area"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                    layout
                    className="mb-4 bg-card/80 backdrop-blur-md rounded-lg p-4 shadow-sm overflow-hidden"
                  >
                    <h3 className="text-sm font-semibold mb-2">Seçili Alan</h3>
                    <p className="text-2xl font-bold">{areaInSqM.toFixed(2)} m²</p>
                  </motion.div>
                )}
              </AnimatePresence>

              <div>
                <h2 className="text-base font-semibold mb-2">Kullanıcı Girişi</h2>
                <div className="space-y-5">
                  <div>
                    <Label htmlFor="monthly-cost" className="mb-2 block">Aylık Elektrik Faturası (TL)</Label>
                    <input
                      id="monthly-cost"
                      type="number"
                      placeholder="TL"
                      className="w-full p-3 border rounded bg-background text-foreground focus:ring-2 focus:ring-ring focus:border-ring"
                      value={monthlyCost}
                      onChange={(e) => setMonthlyCost(e.target.value)}
                    />
                  </div>
                  <div className="bg-black bg-opacity-50 backdrop-blur-md p-3 rounded-lg shadow-lg">
                    <h2 className="text-base font-semibold mb-2 text-white">Montaj Şeklini Seçin</h2>
                    <div className="relative w-full flex justify-between items-stretch gap-2">
                      <button
                        onClick={() => setRoofCoverage('full')}
                        className={`flex-1 flex items-center justify-center p-4 rounded-lg transition-all duration-300 ${
                          roofCoverage === 'full' 
                            ? 'bg-white/20 text-white' 
                            : 'bg-white/10 text-white/60 hover:bg-white/15'
                        }`}
                      >
                        <Sun className="w-5 h-5 mr-2" />
                        <span className="text-sm">Tüm Çatıyı Kaplayın</span>
                      </button>

                      <button
                        onClick={() => setRoofCoverage('partial')}
                        className={`flex-1 flex items-center justify-center p-4 rounded-lg transition-all duration-300 ${
                          roofCoverage === 'partial' 
                            ? 'bg-white/20 text-white' 
                            : 'bg-white/10 text-white/60 hover:bg-white/15'
                        }`}
                      >
                        <Home className="w-5 h-5 mr-2" />
                        <span className="text-sm">Elektrik Faturamı Karşılasın</span>
                      </button>
                    </div>
                    <motion.p
                      key={roofCoverage}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                      className="mt-3 text-xs text-white text-opacity-80"
                    >
                      {roofCoverage === 'full'
                        ? 'Tam çatı kaplamasıyla en yüksek güneş potansiyelini kullanın, gelecekteki ihtiyaçlarınız için fazla enerji üretmeyi mümkün kılabilir veya kullanılmayan enerjiyi şebekeye gönderebilirsiniz.'
                        : 'Şu anki enerji ihtiyaçlarınızı karşılamak için kısmi kaplamayı optimize edin, başlangıç masrafınızı en aza indirmek ve elektrik faturanızı karşılamayı sağlayabilirsiniz.'}
                    </motion.p>
                  </div>
                </div>
              </div>
              <Button
                className="w-full"
                onClick={() => setShowSecondSection(true)}
                disabled={!selectedArea || !areaInSqM || !monthlyCost || !roofCoverage}
                data-tutorial="continue-button"
              >
                Devam Et
              </Button>
            </>
          ) : (
            <SecondSection
              onBack={() => setShowSecondSection(false)}
              onContinue={handleNextClick}
              setMapCenter={setMapCenter}
              searchAddress={searchAddress}
              setSearchAddress={setSearchAddress}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
              handleSearch={handleSearch}
              handleSuggestions={handleSuggestions}
              handleSelectSuggestion={handleSelectSuggestion}
              handleFindMe={handleFindMe}
              showSuggestionsInPanel={showSuggestionsInPanel}
              setShowSuggestionsInPanel={setShowSuggestionsInPanel}
            />
          )}
        </aside>
      </main>

      <footer className="bg-card border-t border-border mt-auto hidden md:block">
        <div className="container mx-auto py-4 px-4">
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 text-sm items-center">
            <div className="space-y-2">
              <h3 className="font-semibold">İletişim</h3>
              <ul className="space-y-1 text-muted-foreground">
                <li className="flex items-center">
                  <Phone className="w-3 h-3 mr-1" />
                  <span>{config.contact_phone}</span>
                </li>
                <li className="flex items-center">
                  <Mail className="w-3 h-3 mr-1" />
                  <span>{config.contact_email}</span>
                </li>
              </ul>
            </div>

            <div className="space-y-2">
              <h3 className="font-semibold">Bağlantılar</h3>
              <ul className="space-y-1">
                <li>
                  <a 
                    href="/about" 
                    onClick={(e) => handleFooterLinkClick('about', e)}
                    className="text-muted-foreground hover:text-primary"
                  >
                    Hakkımızda
                  </a>
                </li>
                <li>
                  <a 
                    href="/contact" 
                    onClick={(e) => handleFooterLinkClick('contact', e)}
                    className="text-muted-foreground hover:text-primary"
                  >
                    İletişim
                  </a>
                </li>
                <li>
                  <a 
                    href="/blog" 
                    onClick={(e) => handleFooterLinkClick('blog', e)}
                    className="text-muted-foreground hover:text-primary"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>

            <div className="space-y-2">
              <h3 className="font-semibold">Yasal</h3>
              <ul className="space-y-1">
                <li>
                  <a 
                    href="/kullanim-kosullari" 
                    onClick={(e) => handleFooterLinkClick('kullanim-kosullari', e)}
                    className="text-muted-foreground hover:text-primary"
                  >
                    Kullanım Koşulları
                  </a>
                </li>
                <li>
                  <a 
                    href="/kvkk" 
                    onClick={(e) => handleFooterLinkClick('kvkk', e)}
                    className="text-muted-foreground hover:text-primary"
                  >
                    KVKK
                  </a>
                </li>
              </ul>
            </div>

            <div className="space-y-2">
              <h3 className="font-semibold">Sosyal Medya</h3>
              <div className="flex space-x-2">
                <a href={config.social_facebook} target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-primary">
                  <Facebook className="w-4 h-4" />
                </a>
                <a href={config.social_twitter} target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-primary">
                  <Twitter className="w-4 h-4" />
                </a>
                <a href={config.social_instagram} target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-primary">
                  <Instagram className="w-4 h-4" />
                </a>
              </div>
              <div className="flex space-x-1 mt-2">
                <Input 
                  type="email" 
                  placeholder="E-posta" 
                  className="h-8 text-xs"
                  value={newsletterEmail}
                  onChange={(e) => setNewsletterEmail(e.target.value)}
                  disabled={isSubscribing}
                />
                <Button 
                  variant="outline" 
                  size="icon" 
                  className="h-8 w-8"
                  onClick={handleNewsletterSubmit}
                  disabled={isSubscribing}
                >
                  {isSubscribing ? (
                    <span className="animate-spin">⌛</span>
                  ) : (
                    <Send className="w-3 h-3" />
                  )}
                </Button>
              </div>
            </div>

            <div className="text-sm text-muted-foreground text-right">
              Copyright © {new Date().getFullYear()}. Tüm hakları saklıdır.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MainPage;