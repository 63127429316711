import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { PlusIcon, PencilIcon, TrashIcon, EyeIcon, ImageIcon } from 'lucide-react';

interface BlogPost {
  id: number;
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  meta_description: string;
  meta_keywords: string;
  status: 'published' | 'draft';
  featured_image?: string;
}

// Başlıktan slug oluşturan yardımcı fonksiyon
const createSlug = (title: string): string => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, '') // Türkçe karakterleri ve özel karakterleri kaldır
    .replace(/\s+/g, '-') // Boşlukları tire ile değiştir
    .replace(/-+/g, '-'); // Birden fazla tireyi tek tireye dönüştür
};

const BlogManagement: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [activeTab, setActiveTab] = useState<'edit' | 'preview'>('edit');
  const [imageUrl, setImageUrl] = useState('');
  const [featuredImageUrl, setFeaturedImageUrl] = useState('');

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_blog_posts`);
      const data = await response.json();
      if (data.status === 'success') {
        setPosts(data.posts);
      }
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
  };

  const handleCreatePost = () => {
    setSelectedPost({
      id: 0,
      title: '',
      slug: '',
      content: '',
      excerpt: '',
      meta_description: '',
      meta_keywords: '',
      status: 'draft',
      featured_image: ''
    });
    setIsCreating(true);
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (!selectedPost) return;

    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}?action=${isCreating ? 'create_blog_post' : 'update_blog_post'}`;
      const response = await fetch(url, {
        method: isCreating ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedPost),
      });

      const data = await response.json();
      if (data.status === 'success') {
        fetchPosts();
        setIsEditing(false);
        setIsCreating(false);
        setSelectedPost(null);
      }
    } catch (error) {
      console.error('Error saving blog post:', error);
    }
  };

  const handleDelete = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=delete_blog_post`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        fetchPosts();
        setSelectedPost(null);
      }
    } catch (error) {
      console.error('Error deleting blog post:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (selectedPost) {
      const value = e.target.value;
      const updates: Partial<BlogPost> = {
        [e.target.name]: value,
      };

      // Başlık değiştiğinde otomatik olarak slug oluştur
      if (e.target.name === 'title') {
        updates.slug = createSlug(value);
      }

      setSelectedPost({
        ...selectedPost,
        ...updates,
      });
    }
  };

  const handleImageInsert = () => {
    if (imageUrl && selectedPost) {
      const imageHtml = `\n<img src="${imageUrl}" alt="Blog image" class="w-full rounded-lg my-4" />\n`;
      setSelectedPost({
        ...selectedPost,
        content: selectedPost.content + imageHtml
      });
      setImageUrl('');
    }
  };

  const handleSetFeaturedImage = () => {
    if (featuredImageUrl && selectedPost) {
      setSelectedPost({
        ...selectedPost,
        featured_image: featuredImageUrl
      });
      setFeaturedImageUrl('');
    }
  };

  const renderPreview = () => {
    if (!selectedPost) return null;

    return (
      <div className="prose prose-lg max-w-none">
        <h1 className="text-4xl font-bold mb-4">{selectedPost.title}</h1>
        <div className="text-sm text-muted-foreground mb-8">
          Preview Mode
        </div>
        <div 
          className="blog-content"
          dangerouslySetInnerHTML={{ __html: selectedPost.content }}
        />
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <Card className="md:col-span-1">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Blog Yazıları</CardTitle>
          <Button onClick={handleCreatePost} size="sm">
            <PlusIcon className="w-4 h-4 mr-2" />
            Yeni Yazı
          </Button>
        </CardHeader>
        <CardContent>
          <ul className="space-y-2">
            {posts.map((post) => (
              <li key={post.id} className="flex items-center justify-between">
                <Button
                  variant={selectedPost?.id === post.id ? 'default' : 'outline'}
                  onClick={() => setSelectedPost(post)}
                  className="flex-1 text-left mr-2"
                >
                  {post.title}
                </Button>
                <div className="flex space-x-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setSelectedPost(post);
                      setIsEditing(true);
                    }}
                  >
                    <PencilIcon className="w-4 h-4" />
                  </Button>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => handleDelete(post.id)}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      {selectedPost && (isEditing || isCreating) && (
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle className="flex justify-between items-center">
              {isCreating ? 'Yeni Blog Yazısı' : 'Blog Yazısını Düzenle'}
              <div className="flex space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setActiveTab(activeTab === 'edit' ? 'preview' : 'edit')}
                >
                  {activeTab === 'edit' ? <EyeIcon className="w-4 h-4" /> : <PencilIcon className="w-4 h-4" />}
                  {activeTab === 'edit' ? 'Önizleme' : 'Düzenle'}
                </Button>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Tabs value={activeTab} onValueChange={(value: string) => setActiveTab(value as 'edit' | 'preview')}>
              <TabsContent value="edit">
                <form className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">Başlık</label>
                    <Input
                      name="title"
                      value={selectedPost.title}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* Gizli slug input */}
                  <input
                    type="hidden"
                    name="slug"
                    value={selectedPost.slug}
                  />

                  <div className="space-y-2">
                    <label className="block text-sm font-medium mb-1">Kapak Görseli</label>
                    <div className="flex space-x-2">
                      <Input
                        placeholder="Kapak görseli URL'si"
                        value={featuredImageUrl}
                        onChange={(e) => setFeaturedImageUrl(e.target.value)}
                      />
                      <Button 
                        type="button"
                        onClick={handleSetFeaturedImage}
                        disabled={!featuredImageUrl}
                      >
                        <ImageIcon className="w-4 h-4 mr-2" />
                        Kapak Görseli Ekle
                      </Button>
                    </div>
                    {selectedPost.featured_image && (
                      <div className="mt-2">
                        <img 
                          src={selectedPost.featured_image} 
                          alt="Kapak görseli" 
                          className="max-h-40 rounded-lg"
                        />
                      </div>
                    )}
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium mb-1">İçerik Görseli Ekle</label>
                    <div className="flex space-x-2">
                      <Input
                        placeholder="Görsel URL'si"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                      />
                      <Button 
                        type="button"
                        onClick={handleImageInsert}
                        disabled={!imageUrl}
                      >
                        <ImageIcon className="w-4 h-4 mr-2" />
                        İçeriğe Ekle
                      </Button>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">İçerik</label>
                    <Textarea
                      name="content"
                      value={selectedPost.content}
                      onChange={handleInputChange}
                      rows={15}
                      className="font-mono"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">Özet</label>
                    <Textarea
                      name="excerpt"
                      value={selectedPost.excerpt}
                      onChange={handleInputChange}
                      rows={3}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">Meta Açıklama</label>
                    <Input
                      name="meta_description"
                      value={selectedPost.meta_description}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">Meta Anahtar Kelimeler</label>
                    <Input
                      name="meta_keywords"
                      value={selectedPost.meta_keywords}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">Durum</label>
                    <select
                      name="status"
                      value={selectedPost.status}
                      onChange={handleInputChange}
                      className="w-full border rounded-md p-2"
                    >
                      <option value="draft">Taslak</option>
                      <option value="published">Yayında</option>
                    </select>
                  </div>
                </form>
              </TabsContent>
              
              <TabsContent value="preview" className="bg-white p-6 rounded-lg">
                {renderPreview()}
              </TabsContent>
            </Tabs>

            <div className="flex space-x-2 mt-4">
              <Button onClick={handleSave}>
                {isCreating ? 'Yazıyı Oluştur' : 'Değişiklikleri Kaydet'}
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  setIsEditing(false);
                  setIsCreating(false);
                  setSelectedPost(null);
                }}
              >
                İptal
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default BlogManagement;