export function safeJSONParse<T>(text: string | null | undefined, defaultValue: T): T {
  if (text == null || text === 'undefined' || text === '') {
    return defaultValue;
  }
  try {
    return JSON.parse(text) as T;
  } catch (e) {
    console.error('Error parsing JSON:', e);
    return defaultValue;
  }
}
