import { useState, useEffect, useCallback } from 'react';
import { updateUserDarkModePreference, getDarkModePreference } from '../services/api';

export const useTheme = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>(() => {
    if (typeof window !== 'undefined') {
      return document.documentElement.classList.contains('dark') ? 'dark' : 'light';
    }
    return 'light';
  });

  useEffect(() => {
    const fetchDarkModePreference = async () => {
      try {
        const { darkModePreference } = await getDarkModePreference();
        const newTheme = darkModePreference ? 'dark' : 'light';
        setTheme(newTheme);
      } catch (error) {
        console.error('Failed to fetch dark mode preference:', error);
      }
    };

    fetchDarkModePreference();
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = useCallback(async () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    try {
      await updateUserDarkModePreference(newTheme === 'dark');
    } catch (error) {
      console.error('Failed to update user theme preference:', error);
    }
  }, [theme]);

  return { theme, toggleTheme };
};