import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Table, Thead, Tbody, Tr, Th, Td } from './ui/Table';
import { Button } from './ui/button';
import { Trash2Icon } from 'lucide-react';
import * as XLSX from 'xlsx';
import { exportSubscribersToExcel } from '../services/api';

interface Subscriber {
  id: number;
  email: string;
  status: 'active' | 'unsubscribed';
  created_at: string;
}

const NewsletterManagement: React.FC = () => {
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSubscribers();
  }, []);

  const fetchSubscribers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/newsletter.php?action=get_subscribers`);
      const data = await response.json();
      
      if (data.status === 'success') {
        setSubscribers(data.subscribers);
      }
    } catch (error) {
      console.error('Aboneler yüklenirken hata:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    if (!window.confirm('Bu aboneyi silmek istediğinizden emin misiniz?')) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'delete_subscriber',
          id: id
        }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        await fetchSubscribers();
      }
    } catch (error) {
      console.error('Abone silinirken hata:', error);
    }
  };

  const handleExportExcel = async () => {
    try {
        const response = await exportSubscribersToExcel();
        
        if (response.status === 'success' && response.data) {
            // Excel dosyası oluştur
            const wb = XLSX.utils.book_new();
            
            // Aboneler sayfası
            const subscribersWS = XLSX.utils.json_to_sheet(response.data);
            
            // Sütun genişliklerini ayarla
            const colWidths = [
                { wch: 10 }, // ID
                { wch: 40 }, // E-posta
                { wch: 15 }, // Durum
                { wch: 20 }, // Kayıt Tarihi
            ];
            subscribersWS['!cols'] = colWidths;
            
            XLSX.utils.book_append_sheet(wb, subscribersWS, "Bülten Aboneleri");
            
            // Excel dosyasını indir
            XLSX.writeFile(wb, `newsletter_subscribers_${new Date().toISOString().split('T')[0]}.xlsx`);
        } else {
            throw new Error(response.message || 'Export failed');
        }
    } catch (error) {
        console.error('Error exporting to Excel:', error);
        alert('Excel export failed: ' + (error instanceof Error ? error.message : 'Unknown error'));
    }
  };

  if (loading) return <div>Yükleniyor...</div>;

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <CardTitle>Bülten Aboneleri</CardTitle>
          <Button onClick={handleExportExcel} variant="outline">
            Excel'e Aktar
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <Table>
          <Thead>
            <Tr>
              <Th>E-posta</Th>
              <Th>Durum</Th>
              <Th>Kayıt Tarihi</Th>
              <Th>İşlemler</Th>
            </Tr>
          </Thead>
          <Tbody>
            {subscribers.map((subscriber) => (
              <Tr key={subscriber.id}>
                <Td>{subscriber.email}</Td>
                <Td>
                  {subscriber.status === 'active' ? 'Aktif' : 'Abonelikten Çıktı'}
                </Td>
                <Td>
                  {new Date(subscriber.created_at).toLocaleDateString('tr-TR')}
                </Td>
                <Td>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => handleDelete(subscriber.id)}
                  >
                    <Trash2Icon className="w-4 h-4" />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default NewsletterManagement;