import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import DarkModeToggle from './DarkModeToggle';
import { getSystemConfig } from '../services/api';

// Page interface'ini tanımlayalım
interface Page {
  id: number;
  title: string;
  slug: string;
  content: string;
  meta_description?: string;
  meta_keywords?: string;
  status: 'published' | 'draft';
}

interface SystemConfig {
  site_name: string;
  logo_url: string;
  header_display_type: 'text' | 'logo';
  contact_phone: string;
  contact_email: string;
}

const Navigation: React.FC = () => {
  const { user, logout } = useAuth();
  // pages state'ine Page[] tipini verelim
  const [pages, setPages] = useState<Page[]>([]);
  const [config, setConfig] = useState<SystemConfig>({
    site_name: 'Solar Simulation',
    logo_url: '',
    header_display_type: 'text',
    contact_phone: '+90 (XXX) XXX XX XX',
    contact_email: 'info@example.com'
  });

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_pages`);
        const data = await response.json();
        if (data.status === 'success') {
          setPages(data.pages);
        }
      } catch (error) {
        console.error('Error fetching pages:', error);
      }
    };

    fetchPages();
  }, []);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await getSystemConfig();
        if (response.status === 'success') {
          setConfig(response.config);
        }
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };
    fetchConfig();
  }, []);

  const handlePageClick = async (e: React.MouseEvent<HTMLAnchorElement>, page: Page) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_page&slug=${page.slug}`);
      const data = await response.json();
      
      if (data.status === 'success' && data.redirect) {
        window.location.href = data.redirect_url;
      } else {
        window.location.href = `/${page.slug}`;
      }
    } catch (error) {
      console.error('Error:', error);
      window.location.href = `/${page.slug}`;
    }
  };

  const renderHeader = () => {
    if (config.header_display_type === 'logo' && config.logo_url) {
      return (
        <img 
          src={config.logo_url} 
          alt={config.site_name}
          className="h-8 w-auto"
        />
      );
    }
    return (
      <h1 className="text-2xl font-bold text-foreground">
        {config.site_name}
      </h1>
    );
  };

  const navigationPages = pages.filter(page => 
    !['kullanim-kosullari', 'kvkk'].includes(page.slug)
  );

  return (
    <nav className="fixed top-0 left-0 right-0 z-[1002] bg-transparent">
      <div className="absolute inset-0 bg-background/40 backdrop-blur-[6px]" />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-transparent">
        <div className="flex items-center justify-between h-16 bg-transparent">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              {renderHeader()}
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            {navigationPages.map((page) => (
              <Link
                key={page.slug}
                to={`/${page.slug}`}
                onClick={(e) => handlePageClick(e, page)}
                className="text-foreground/90 hover:text-primary transition-colors duration-200 px-3 py-2 rounded-md text-sm font-medium"
              >
                {page.title}
              </Link>
            ))}
            {user && (
              <>
                <Link 
                  to="/admin" 
                  className="text-foreground/90 hover:text-primary transition-colors duration-200 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Admin Dashboard
                </Link>
                <button 
                  onClick={logout} 
                  className="text-foreground/90 hover:text-primary transition-colors duration-200 px-3 py-2 rounded-md text-sm font-medium"
                >
                  Logout
                </button>
              </>
            )}
            <DarkModeToggle />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;