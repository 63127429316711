import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Link2Icon, FileTextIcon } from 'lucide-react';

interface Page {
  id: number;
  title: string;
  content: string;
  slug: string;
  page_type: 'content' | 'redirect';
  redirect_url?: string;
  featured_image?: string;
  meta_description?: string;
  meta_keywords?: string;
  map_embed?: string;
  contact_info?: {
    address?: string;
    phone?: string;
    email?: string;
    working_hours?: string;
  };
}

const ContentEditor: React.FC = () => {
  const [pages, setPages] = useState<Page[]>([]);
  const [selectedPage, setSelectedPage] = useState<Page | null>(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_pages`);
      const data = await response.json();
      if (data.status === 'success') {
        setPages(data.pages);
      } else {
        console.error('Failed to fetch pages:', data.message);
      }
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  const handlePageSelect = (page: Page) => {
    setSelectedPage(page);
    setEditMode(false);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (selectedPage) {
      setSelectedPage({
        ...selectedPage,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handlePageTypeChange = (type: 'content' | 'redirect') => {
    if (selectedPage) {
      setSelectedPage({
        ...selectedPage,
        page_type: type,
        // Eğer içerik tipine geçilirse redirect_url'i temizle
        ...(type === 'content' && { redirect_url: '' }),
        // Eğer yönlendirme tipine geçilirse içeriği temizle
        ...(type === 'redirect' && { content: '' })
      });
    }
  };

  const handleSave = async () => {
    if (selectedPage) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=update_page`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: selectedPage.id,
            title: selectedPage.title,
            content: selectedPage.content,
            slug: selectedPage.slug,
            contact_info: selectedPage.slug === 'contact' ? {
              address: selectedPage.contact_info?.address || '',
              phone: selectedPage.contact_info?.phone || '',
              email: selectedPage.contact_info?.email || '',
              working_hours: selectedPage.contact_info?.working_hours || ''
            } : null
          }),
        });
        
        const data = await response.json();
        
        if (data.status === 'success') {
          fetchPages();
          setEditMode(false);
          alert('Sayfa başarıyla güncellendi');
        } else {
          alert(data.message || 'Sayfa güncellenirken bir hata oluştu');
        }
      } catch (error) {
        console.error('Error updating page:', error);
        alert('Sayfa güncellenirken bir hata oluştu');
      }
    }
  };

  const validateUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <Card className="md:col-span-1">
        <CardHeader>
          <CardTitle>Sayfalar</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="space-y-2">
            {pages.map((page) => (
              <li key={page.id}>
                <Button
                  variant={selectedPage?.id === page.id ? 'default' : 'outline'}
                  onClick={() => handlePageSelect(page)}
                  className="w-full text-left flex items-center"
                >
                  {page.page_type === 'redirect' ? (
                    <Link2Icon className="w-4 h-4 mr-2" />
                  ) : (
                    <FileTextIcon className="w-4 h-4 mr-2" />
                  )}
                  {page.title}
                </Button>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      {selectedPage && (
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle>
              {editMode ? 'Sayfayı Düzenle' : selectedPage.title}
            </CardTitle>
          </CardHeader>
          <CardContent>
            {editMode ? (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Başlık</label>
                  <Input
                    id="title"
                    name="title"
                    value={selectedPage.title}
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Sayfa Tipi</label>
                  <div className="flex space-x-4 mb-4">
                    <Button
                      variant={selectedPage.page_type === 'content' ? 'default' : 'outline'}
                      onClick={() => handlePageTypeChange('content')}
                      className="flex items-center"
                    >
                      <FileTextIcon className="w-4 h-4 mr-2" />
                      İçerik
                    </Button>
                    <Button
                      variant={selectedPage.page_type === 'redirect' ? 'default' : 'outline'}
                      onClick={() => handlePageTypeChange('redirect')}
                      className="flex items-center"
                    >
                      <Link2Icon className="w-4 h-4 mr-2" />
                      Yönlendirme
                    </Button>
                  </div>
                </div>

                {selectedPage.page_type === 'redirect' ? (
                  <div>
                    <label className="block text-sm font-medium mb-1">Yönlendirme URL'i</label>
                    <Input
                      id="redirect_url"
                      name="redirect_url"
                      value={selectedPage.redirect_url || ''}
                      onChange={handleInputChange}
                      placeholder="https://example.com"
                    />
                    {selectedPage.redirect_url && !validateUrl(selectedPage.redirect_url) && (
                      <p className="text-red-500 text-sm mt-1">Geçerli bir URL giriniz</p>
                    )}
                  </div>
                ) : (
                  <>
                    <div>
                      <label className="block text-sm font-medium mb-1">Kapak Görseli URL</label>
                      <Input
                        name="featured_image"
                        value={selectedPage.featured_image || ''}
                        onChange={handleInputChange}
                        placeholder="https://example.com/image.jpg"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium mb-1">İçerik</label>
                      <Textarea
                        name="content"
                        value={selectedPage.content}
                        onChange={handleInputChange}
                        rows={10}
                      />
                    </div>

                    {selectedPage.slug === 'contact' && (
                      <>
                        <div>
                          <label className="block text-sm font-medium mb-1">Google Maps Embed Kodu</label>
                          <Textarea
                            name="map_embed"
                            value={selectedPage.map_embed || ''}
                            onChange={handleInputChange}
                            rows={4}
                            placeholder="<iframe src='...' ...></iframe>"
                          />
                        </div>

                        <div className="space-y-4">
                          <h3 className="text-lg font-medium">İletişim Bilgileri</h3>
                          
                          <div>
                            <label className="block text-sm font-medium mb-1">Adres</label>
                            <Input
                              name="contact_info.address"
                              value={selectedPage.contact_info?.address || ''}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium mb-1">Telefon</label>
                            <Input
                              name="contact_info.phone"
                              value={selectedPage.contact_info?.phone || ''}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium mb-1">E-posta</label>
                            <Input
                              name="contact_info.email"
                              value={selectedPage.contact_info?.email || ''}
                              onChange={handleInputChange}
                            />
                          </div>

                          <div>
                            <label className="block text-sm font-medium mb-1">Çalışma Saatleri</label>
                            <Input
                              name="contact_info.working_hours"
                              value={selectedPage.contact_info?.working_hours || ''}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                
                <div className="flex space-x-2 mt-4">
                  <Button onClick={handleSave}>Değişiklikleri Kaydet</Button>
                  <Button variant="outline" onClick={() => setEditMode(false)}>İptal</Button>
                </div>
              </div>
            ) : (
              <div>
                {selectedPage.page_type === 'redirect' ? (
                  <div className="flex items-center space-x-2">
                    <Link2Icon className="w-4 h-4" />
                    <a 
                      href={selectedPage.redirect_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                    >
                      {selectedPage.redirect_url}
                    </a>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: selectedPage.content }} />
                )}
                <Button onClick={handleEditToggle} className="mt-4">Sayfayı Düzenle</Button>
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ContentEditor;