import React, { ReactNode } from 'react';

interface TableProps {
  children: ReactNode;
  className?: string;
}

export const Table: React.FC<TableProps> = ({ children, className = '' }) => {
  return (
    <div className="overflow-x-auto">
      <table className={`min-w-full divide-y divide-gray-200 ${className}`}>
        {children}
      </table>
    </div>
  );
};

interface TheadProps {
  children: ReactNode;
  className?: string;
}

export const Thead: React.FC<TheadProps> = ({ children, className = '' }) => {
  return <thead className={`bg-gray-50 ${className}`}>{children}</thead>;
};

interface TbodyProps {
  children: ReactNode;
  className?: string;
}

export const Tbody: React.FC<TbodyProps> = ({ children, className = '' }) => {
  return <tbody className={`bg-white divide-y divide-gray-200 ${className}`}>{children}</tbody>;
};

interface TrProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const Tr: React.FC<TrProps> = ({ children, className = '', onClick }) => {
  return <tr className={className} onClick={onClick}>{children}</tr>;
};

interface ThProps {
  children: ReactNode;
  className?: string;
}

export const Th: React.FC<ThProps> = ({ children, className = '' }) => {
  return (
    <th
      scope="col"
      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${className}`}
    >
      {children}
    </th>
  );
};

interface TdProps {
  children: ReactNode;
  className?: string;
  colSpan?: number;
}

export const Td: React.FC<TdProps> = ({ children, className = '', colSpan }) => {
  return (
    <td className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500 ${className}`} colSpan={colSpan}>
      {children}
    </td>
  );
};