import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import QuoteManagement from '../components/QuoteManagement';
import ContentEditor from '../components/ContentEditor';
import SystemConfig from '../components/SystemConfig';
import UserAnalytics from '../components/UserAnalytics';
import SimulationSettings from '../components/SimulationSettings';
import AdminManagement from '../components/AdminManagement';
import BlogManagement from '../components/BlogManagement';
import PanelManagement from '../components/PanelManagement';
import NewsletterManagement from '../components/NewsletterManagement';
import EmailConfiguration from '../components/EmailConfiguration';
import { Button } from '../components/ui/button';

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [activeTab, setActiveTab] = useState('analytics');

  useEffect(() => {
    if (!isAuthenticated || !user?.isAdmin) {
      navigate('/login');
    }
  }, [isAuthenticated, user, navigate]);

  const tabs = [
    { id: 'analytics', label: 'Ziyaretçi Raporları' },
    { id: 'content', label: 'Sayfa Ayarları' },
    { id: 'blog', label: 'Blog Yönetimi' },
    { id: 'quotes', label: 'Hesaplama Ve İletişim Raporları' },
    { id: 'config', label: 'Sistem Ayarları' },
    { id: 'simulation', label: 'Simülasyon Ayarları' },
    { id: 'panels', label: 'Panel Yönetimi' },
    { id: 'admins', label: 'Yönetici Ayarları' },
    { id: 'newsletter', label: 'Bülten Aboneleri' },
    { id: 'email', label: 'Email Ayarları' },
  ];

  return (
    <div className="admin-dashboard p-6 bg-background text-foreground min-h-screen">
      <div>""</div>
      <div>""</div>
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <nav className="admin-nav mb-6 flex flex-wrap">
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            variant={activeTab === tab.id ? 'default' : 'outline'}
            onClick={() => setActiveTab(tab.id)}
            className="mr-2 mb-2"
          >
            {tab.label}
          </Button>
        ))}
      </nav>
      <div className="admin-content bg-card shadow-md rounded-lg p-6">
        {activeTab === 'analytics' && <UserAnalytics />}
        {activeTab === 'content' && <ContentEditor />}
        {activeTab === 'blog' && <BlogManagement />}
        {activeTab === 'quotes' && <QuoteManagement />}
        {activeTab === 'config' && <SystemConfig />}
        {activeTab === 'simulation' && <SimulationSettings />}
        {activeTab === 'panels' && <PanelManagement />}
        {activeTab === 'admins' && <AdminManagement />}
        {activeTab === 'newsletter' && <NewsletterManagement />}
        {activeTab === 'email' && <EmailConfiguration />}
      </div>
    </div>
  );
};

export default AdminDashboard;