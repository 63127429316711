import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { getSystemConfig, updateSystemConfig } from '../services/api';
import { Label } from '../components/ui/label';

interface ConfigItem {
  key: string;
  value: string;
}

const SystemConfig: React.FC = () => {
  const [config, setConfig] = useState<ConfigItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const data = await getSystemConfig();
      if (data.status === 'success' && typeof data.config === 'object') {
        const configArray = Object.entries(data.config).map(([key, value]) => ({
          key,
          value: String(value)
        }));
        setConfig(configArray);
      } else {
        throw new Error(`Invalid configuration data received: ${JSON.stringify(data)}`);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching configuration:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
      setLoading(false);
    }
  };

  const handleConfigChange = (key: string, value: string) => {
    const updatedConfig = config.map(item => 
      item.key === key ? { ...item, value } : item
    );
    setConfig(updatedConfig);
  };

  const handleSaveConfig = async () => {
    try {
      const configObject = config.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {} as Record<string, string>);

      const response = await updateSystemConfig({ config: configObject });
      if (response.status === 'success') {
        alert('Yapılandırma başarıyla güncellendi');
        window.location.reload();
      } else {
        throw new Error(response.message || 'Yapılandırma güncellenemedi');
      }
    } catch (error) {
      console.error('Error updating configuration:', error);
      alert('Yapılandırma güncellenirken hata oluştu');
    }
  };

  const renderConfigInput = (item: ConfigItem) => {
    const displayType = config.find(i => i.key === 'header_display_type')?.value;

    switch (item.key) {
      case 'header_display_type':
        return (
          <Select
            value={item.value}
            onValueChange={(value) => handleConfigChange(item.key, value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Görüntüleme tipini seçin" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="text">Site İsmini Göster</SelectItem>
              <SelectItem value="logo">Logo Göster</SelectItem>
            </SelectContent>
          </Select>
        );

      case 'contact_phone':
        return (
          <Input
            type="tel"
            value={item.value}
            onChange={(e) => handleConfigChange(item.key, e.target.value)}
            placeholder="+90 (XXX) XXX XX XX"
            className="mt-1"
          />
        );

      case 'contact_email':
        return (
          <Input
            type="email"
            value={item.value}
            onChange={(e) => handleConfigChange(item.key, e.target.value)}
            placeholder="info@example.com"
            className="mt-1"
          />
        );

      case 'logo_url':
        if (displayType !== 'logo') return null;
        return (
          <Input
            type="text"
            value={item.value}
            onChange={(e) => handleConfigChange(item.key, e.target.value)}
            placeholder="Logo URL'sini girin"
            className="mt-1"
          />
        );

      case 'social_facebook':
      case 'social_twitter':
      case 'social_instagram':
        return (
          <Input
            type="url"
            value={item.value}
            onChange={(e) => handleConfigChange(item.key, e.target.value)}
            placeholder="https://example.com"
            className="mt-1"
          />
        );

      default:
        return (
          <Input
            type="text"
            value={item.value}
            onChange={(e) => handleConfigChange(item.key, e.target.value)}
            className="mt-1"
          />
        );
    }
  };

  if (loading) return <div>Yapılandırma yükleniyor...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Sistem Yapılandırması</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Genel Ayarlar</h3>
            {config
              .filter(item => ['site_name', 'header_display_type', 'logo_url'].includes(item.key))
              .map((item) => (
                <div key={item.key} className="space-y-2">
                  <Label>
                    {item.key === 'site_name' && 'Site İsmi'}
                    {item.key === 'logo_url' && 'Logo URL'}
                    {item.key === 'header_display_type' && 'Başlık Görüntüleme Tipi'}
                  </Label>
                  {renderConfigInput(item)}
                </div>
              ))}
          </div>

          <div className="h-px bg-border my-6" />

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">İletişim Bilgileri</h3>
            {config
              .filter(item => ['contact_phone', 'contact_email'].includes(item.key))
              .map((item) => (
                <div key={item.key} className="space-y-2">
                  <Label>
                    {item.key === 'contact_phone' && 'Telefon Numarası'}
                    {item.key === 'contact_email' && 'E-posta Adresi'}
                  </Label>
                  {renderConfigInput(item)}
                </div>
              ))}
          </div>

          <div className="h-px bg-border my-6" />

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Sosyal Medya Bağlantıları</h3>
            {config
              .filter(item => ['social_facebook', 'social_twitter', 'social_instagram'].includes(item.key))
              .map((item) => (
                <div key={item.key} className="space-y-2">
                  <Label>
                    {item.key === 'social_facebook' && 'Facebook'}
                    {item.key === 'social_twitter' && 'Twitter'}
                    {item.key === 'social_instagram' && 'Instagram'}
                  </Label>
                  {renderConfigInput(item)}
                </div>
              ))}
          </div>
        </div>

        <Button onClick={handleSaveConfig} className="mt-6 w-full">
          Yapılandırmayı Kaydet
        </Button>
      </CardContent>
    </Card>
  );
};

export default SystemConfig;