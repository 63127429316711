import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from './ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import { Label } from './ui/label';
import { toast } from './ui/toast';
import { getEmailConfig, updateEmailConfig, getEmailTemplates, updateEmailTemplate } from '../services/api';

interface EmailConfig {
  sendinblue_api_key: string;
  from_email: string;
  from_name: string;
  admin_email: string;
}

interface EmailTemplate {
  template_key: string;
  subject: string;
  body: string;
}

const EmailConfiguration: React.FC = () => {
  const [config, setConfig] = useState<EmailConfig>({
    sendinblue_api_key: '',
    from_email: '',
    from_name: '',
    admin_email: '',
  });

  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const [configData, templatesData] = await Promise.all([
        getEmailConfig(),
        getEmailTemplates(),
      ]);
      setConfig(configData);
      setTemplates(templatesData);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load email configuration',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfigChange = (key: keyof EmailConfig) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfig((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const handleTemplateChange = (templateKey: string, field: 'subject' | 'body') => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    setTemplates((prev) =>
      prev.map((template) =>
        template.template_key === templateKey
          ? { ...template, [field]: newValue }
          : template
      )
    );
  };

  const handleSaveConfig = async () => {
    try {
      await updateEmailConfig(config);
      toast({
        title: 'Success',
        description: 'Email configuration saved successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to save email configuration',
        variant: 'destructive',
      });
    }
  };

  const handleSaveTemplate = async (templateKey: string) => {
    try {
        const template = templates.find((t) => t.template_key === templateKey);
        if (!template) {
            throw new Error('Şablon bulunamadı');
        }

        const response = await updateEmailTemplate({
            template_key: templateKey,
            subject: template.subject,
            body: template.body
        });

        if (response.success) {
            toast({
                title: 'Başarılı',
                description: 'Email şablonu başarıyla güncellendi',
            });
            
            // Şablonları yeniden yükle
            const templatesData = await getEmailTemplates();
            setTemplates(templatesData);
        } else {
            throw new Error(response.message || 'Şablon güncellenirken bir hata oluştu');
        }
    } catch (error) {
        console.error('Template update error:', error);
        toast({
            title: 'Hata',
            description: error instanceof Error ? error.message : 'Şablon güncellenirken bir hata oluştu',
            variant: 'destructive',
        });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Email Configuration</CardTitle>
        <CardDescription>
          Configure email settings and templates for the system
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="settings">
          <TabsList>
            <TabsTrigger value="settings">Settings</TabsTrigger>
            <TabsTrigger value="templates">Templates</TabsTrigger>
          </TabsList>

          <TabsContent value="settings">
            <div className="space-y-4">
              <div>
                <Label htmlFor="apiKey">Sendinblue API Key</Label>
                <Input
                  id="apiKey"
                  type="password"
                  value={config.sendinblue_api_key}
                  onChange={handleConfigChange('sendinblue_api_key')}
                />
              </div>
              <div>
                <Label htmlFor="fromEmail">From Email</Label>
                <Input
                  id="fromEmail"
                  type="email"
                  value={config.from_email}
                  onChange={handleConfigChange('from_email')}
                />
              </div>
              <div>
                <Label htmlFor="fromName">From Name</Label>
                <Input
                  id="fromName"
                  value={config.from_name}
                  onChange={handleConfigChange('from_name')}
                />
              </div>
              <div>
                <Label htmlFor="adminEmail">Admin Email</Label>
                <Input
                  id="adminEmail"
                  type="email"
                  value={config.admin_email}
                  onChange={handleConfigChange('admin_email')}
                />
              </div>
              <Button onClick={handleSaveConfig}>Save Settings</Button>
            </div>
          </TabsContent>

          <TabsContent value="templates">
            <div className="space-y-6">
              {templates.map((template) => (
                <div key={template.template_key} className="space-y-4">
                  <h3 className="text-lg font-semibold">
                    {template.template_key.replace(/_/g, ' ').toUpperCase()}
                  </h3>
                  <div>
                    <Label htmlFor={`${template.template_key}-subject`}>Subject</Label>
                    <Input
                      id={`${template.template_key}-subject`}
                      value={template.subject}
                      onChange={handleTemplateChange(template.template_key, 'subject')}
                    />
                  </div>
                  <div>
                    <Label htmlFor={`${template.template_key}-body`}>Body</Label>
                    <Textarea
                      id={`${template.template_key}-body`}
                      value={template.body}
                      onChange={handleTemplateChange(template.template_key, 'body')}
                      rows={10}
                    />
                  </div>
                  <Button onClick={() => handleSaveTemplate(template.template_key)}>
                    Save Template
                  </Button>
                </div>
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default EmailConfiguration;