import React, { useState, useRef, useCallback, useEffect } from 'react';
import L from 'leaflet';
import { motion } from 'framer-motion';
import { Button } from "../../components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import { PencilSquareIcon, CheckIcon, SunIcon, TrashIcon as TrashIconHero, XMarkIcon } from '@heroicons/react/24/outline';
import 'leaflet-draw';

interface RoofSelectionToolProps {
  onDrawingChange: (isDrawing: boolean) => void;
  featureGroupRef: React.RefObject<L.FeatureGroup>;
  mapRef: React.RefObject<L.Map>;
  onPolygonCreated: (polygon: L.Polygon) => void;
  onReset: () => void;
  isMobile: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
}

const RoofSelectionTool: React.FC<RoofSelectionToolProps> = ({
  onDrawingChange,
  featureGroupRef,
  mapRef,
  onPolygonCreated,
  onReset,
  isMobile,
  setIsMobileMenuOpen
}) => {
  const [drawingState, setDrawingState] = useState<'idle' | 'drawing' | 'completed'>('idle');
  const drawControlRef = useRef<L.Draw.Polygon | null>(null);

  const handleDrawingFinished = useCallback((polygon: L.Polygon) => {
    setDrawingState('completed');
    onDrawingChange(false);
    onPolygonCreated(polygon);
    if (drawControlRef.current) {
      drawControlRef.current.disable();
    }
  }, [onDrawingChange, onPolygonCreated]);

  useEffect(() => {
    if (!mapRef.current) return;

    const map = mapRef.current;

    const drawCreatedHandler = ((e: L.DrawEvents.Created) => {
      const layer = e.layer;
      if (layer instanceof L.Polygon) {
        if (featureGroupRef.current) {
          featureGroupRef.current.clearLayers();
          featureGroupRef.current.addLayer(layer);
        }
        handleDrawingFinished(layer);
      }
    }) as L.LeafletEventHandlerFn;

    const drawStartHandler = (() => {
      setDrawingState('drawing');
      onDrawingChange(true);
    }) as L.LeafletEventHandlerFn;

    const drawStopHandler = (() => {
      if (drawingState === 'drawing') {
        const drawControl = drawControlRef.current as any;
        if (drawControl && drawControl._poly) {
          const polygon = drawControl._poly as L.Polygon;
          handleDrawingFinished(polygon);
        }
      }
    }) as L.LeafletEventHandlerFn;

    const drawVertexHandler = ((e: L.DrawEvents.EditVertex) => {
      if (e.layers && e.layers.getLayers().length > 2) {
        const drawControl = drawControlRef.current as any;
        if (drawControl && drawControl._markers) {
          drawControl._markers.forEach((marker: L.Marker) => {
            marker.setIcon(new L.DivIcon({
              iconSize: new L.Point(8, 8),
              className: 'leaflet-div-icon leaflet-editing-icon'
            }));
          });
        }
      }
    }) as L.LeafletEventHandlerFn;

    map.on(L.Draw.Event.CREATED, drawCreatedHandler);
    map.on(L.Draw.Event.DRAWSTART, drawStartHandler);
    map.on(L.Draw.Event.DRAWSTOP, drawStopHandler);
    map.on(L.Draw.Event.DRAWVERTEX, drawVertexHandler);

    return () => {
      map.off(L.Draw.Event.CREATED, drawCreatedHandler);
      map.off(L.Draw.Event.DRAWSTART, drawStartHandler);
      map.off(L.Draw.Event.DRAWSTOP, drawStopHandler);
      map.off(L.Draw.Event.DRAWVERTEX, drawVertexHandler);
    };
  }, [mapRef, featureGroupRef, onDrawingChange, onPolygonCreated, drawingState, handleDrawingFinished]);

  const handleStartDrawing = () => {
    if (mapRef.current) {
      const map = mapRef.current;
      if (drawControlRef.current) {
        drawControlRef.current.disable();
      }
      const drawControl = new L.Draw.Polygon(map as L.DrawMap, {
        shapeOptions: {
          color: '#3388ff',
          weight: 2,
          opacity: 0.7,
          fillOpacity: 0.2
        },
        showArea: true,
        metric: true,
        feet: false,
        allowIntersection: false,
        drawError: {
          color: '#e1e100',
          message: '<strong>Oh snap!</strong> you can\'t draw that!'
        },
        touchIcon: new L.DivIcon({
          iconSize: new L.Point(16, 16),
          className: 'leaflet-div-icon leaflet-editing-icon leaflet-touch-icon'
        }),
        icon: new L.DivIcon({
          iconSize: new L.Point(8, 8),
          className: 'leaflet-div-icon leaflet-editing-icon'
        })
      });
      drawControlRef.current = drawControl;
      drawControl.enable();
      setDrawingState('drawing');
      onDrawingChange(true);
    }
  };

  const handleDeleteLastPoint = () => {
    if (mapRef.current && drawControlRef.current) {
      const draw = drawControlRef.current as any;
      if (draw._markers && draw._markers.length > 0) {
        draw.deleteLastVertex();
      }
    }
  };

  const handleCancel = () => {
    if (mapRef.current && drawControlRef.current) {
      const draw = drawControlRef.current as any;
      draw.disable();
      if (featureGroupRef.current) {
        featureGroupRef.current.clearLayers();
      }
    }
    setDrawingState('idle');
    onDrawingChange(false);
    if (isMobile) {
      setIsMobileMenuOpen(true);
    }
  };

  const handleReset = () => {
    handleCancel();
    onReset();
    if (isMobile) {
      setIsMobileMenuOpen(true);
    }
  };

  const OptionsPanel = () => (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="mt-2 bg-background rounded-md shadow-md p-2 flex space-x-2 border border-border"
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button 
              variant="outline" 
              size="sm" 
              onClick={handleDeleteLastPoint} 
              className="px-3 py-2 hover:bg-accent transition-colors flex items-center space-x-1"
            >
              <TrashIconHero className="w-4 h-4" />
              <span>Geri Al</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Önceki noktayı kaldır</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button 
              variant="outline" 
              size="sm" 
              onClick={handleCancel} 
              className="px-3 py-2 hover:bg-accent transition-colors flex items-center space-x-1"
            >
              <XMarkIcon className="w-4 h-4" />
              <span>İptal</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Seçimi iptal et</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </motion.div>
  );

  return (
    <div className="absolute top-20 left-4 z-[1000]">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={drawingState === 'drawing' ? "secondary" : drawingState === 'completed' ? "outline" : "default"}
              size="lg"
              className={`flex items-center space-x-2 shadow-md transition-all duration-300 ease-in-out ${
                drawingState === 'drawing' ? 'bg-secondary text-secondary-foreground' :
                drawingState === 'completed' ? 'bg-accent text-accent-foreground border-accent hover:bg-accent/90' :
                'bg-primary text-primary-foreground hover:bg-primary/90'
              }`}
              onClick={drawingState === 'completed' ? handleReset : handleStartDrawing}
              disabled={drawingState === 'drawing'}
              data-tutorial="start-drawing"
            >
              {drawingState === 'drawing' && <SunIcon className="w-5 h-5 animate-pulse" />}
              {drawingState === 'completed' && <CheckIcon className="w-5 h-5" />}
              {drawingState === 'idle' && <PencilSquareIcon className="w-5 h-5" />}
              <span>{drawingState === 'completed' ? 'Sıfırla' : drawingState === 'drawing' ? 'Çiziyor...' : 'Çizmeye Başla'}</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{drawingState === 'completed' ? 'Sıfırla ve yeniden başla' : drawingState === 'drawing' ? 'Şu anda çiziyor' : 'Çizmeye başla'}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      {drawingState === 'drawing' && <OptionsPanel />}
    </div>
  );
};

export default RoofSelectionTool;