import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { SendIcon } from 'lucide-react';
import { ArrowLeftIcon } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../components/ui/card';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import { Textarea } from '../components/ui/textarea';
import { Button } from '../components/ui/button';
import { CheckCircle2 } from 'lucide-react';
import { submitQuote } from '../services/api';
import { toast } from '../components/ui/toast';

interface QuoteFormData {
  firstName: string;
  lastName: string;
  address: string;
  phone: string;
  email: string;
  comments: string;
}

const QuotePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const rooftopId = location.state?.rooftopId;
  const [formData, setFormData] = useState<QuoteFormData>({
    firstName: '',
    lastName: '',
    address: '',
    phone: '',
    email: '',
    comments: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(10);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const response = await submitQuote({
        ...formData,
        rooftopId: location.state.rooftopId,
        simulationId: location.state.simulationId,
      });

      if (response.status === 'success') {
        console.log('Quote request submitted successfully!');
        setIsSubmitted(true);
        // Scroll to top to show the notification
        window.scrollTo(0, 0);
        toast({
          title: 'Success',
          description: 'Your quote has been submitted successfully.',
        });
      } else {
        console.error('Error submitting quote request:', response.message);
        alert('Error submitting quote request: ' + response.message);
        toast({
          title: 'Error',
          description: 'Failed to submit quote. Please try again.',
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.error('Error submitting quote:', error);
      alert('An error occurred while submitting the quote. Please try again.');
      toast({
        title: 'Error',
        description: 'An unexpected error occurred. Please try again.',
        variant: 'destructive',
      });
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      const redirectTimer = setTimeout(() => {
        navigate('/');
      }, 10000); // 10 seconds

      return () => {
        clearInterval(timer);
        clearTimeout(redirectTimer);
      };
    }
  }, [isSubmitted, navigate]);

  return (
    <div className="min-h-screen bg-background text-foreground">
      <header className="bg-card p-4 shadow">
        <div className="mx-auto max-w-7xl flex items-center justify-between">
          <h1 className="text-2xl font-bold">Teklif Formu</h1>
          <Button variant="ghost" size="icon" asChild>
            <Link to={`/summary/${rooftopId}`}>
              <ArrowLeftIcon className="h-6 w-6" />
              <span className="sr-only">Back to summary page</span>
            </Link>
          </Button>
        </div>
      </header>
      <main className="mx-auto max-w-3xl p-6">
        {isSubmitted && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-8" role="alert">
            <div className="flex items-center">
              <CheckCircle2 className="h-6 w-6 mr-2" />
              <p className="font-medium">
                Teklif talebiniz alınmıştır.. Uzman ekibimiz en kısa sürede sizinle iletişime geçececktir..
                <br />
                {countdown} Saniye sonra anasayfaya otomatik yönlendirileceksiniz.
              </p>
            </div>
          </div>
        )}
        <Card>
          <CardHeader>
            <CardTitle>Hemen Teklif Alın</CardTitle>
            <CardDescription>Teklif talebinde bulunmak için lütfen aşağıdaki formu doldurun.</CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label htmlFor="firstName">Ad</Label>
                  <Input id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="lastName">Soyad</Label>
                  <Input id="lastName" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="address">Adres</Label>
                <Input id="address" name="address" value={formData.address} onChange={handleInputChange} required />
              </div>
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="space-y-2">
                  <Label htmlFor="phone">Telefon Numarası</Label>
                  <Input id="phone" name="phone" type="tel" value={formData.phone} onChange={handleInputChange} required />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="email">E-Posta Adresi</Label>
                  <Input id="email" name="email" type="email" value={formData.email} onChange={handleInputChange} required />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="comments">Mesaj</Label>
                <Textarea id="comments" name="comments" value={formData.comments} onChange={handleInputChange} />
              </div>
            </form>
          </CardContent>
          <CardFooter>
            <Button className="w-full" onClick={handleSubmit}>
              <SendIcon className="mr-2 h-4 w-4" />
              Teklif Formunu Gönder
            </Button>
          </CardFooter>
        </Card>
      </main>
    </div>
  );
};

export default QuotePage;