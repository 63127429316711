import React from 'react';
import { SummaryData } from '../pages/SummaryPage';
import '../styles/pdfStyles.css';
import { DollarSign, Leaf, Sun, Zap } from 'lucide-react';

interface PDFContentProps {
  summaryData: SummaryData & {
    panelType?: string;
    productionPerHour?: number;
  };
  monthlyChartImage: string;
  energyProductionChartImage: string;
}

const PDFContent: React.FC<PDFContentProps> = ({ summaryData, monthlyChartImage, energyProductionChartImage }) => {
  console.log('Rendering PDFContent with data:', summaryData);

  // Helper function to format numbers
  const formatNumber = (value: number | undefined | null, decimals: number = 2): string => {
    if (typeof value !== 'number') return 'N/A';
    return value.toFixed(decimals);
  };

  return (
    <div className="pdf-container">
      {/* Header */}
      <div className="pdf-header">
        <div className="pdf-logo">
          <Sun className="pdf-logo-icon" />
        </div>
        <div>
          <h1 className="pdf-title">Lumosol® | Pv Enerji® Üretim & Teklif Raporu</h1>
          <p className="pdf-report-number">Report #: {Math.floor(Math.random() * 100000)}</p>
        </div>
      </div>

      {/* Overview Card */}
      <div className="pdf-card">
        <h2 className="pdf-card-title"><Zap className="pdf-icon" /> Projenin Genel Bakışı</h2>
        <div className="pdf-grid">
          {/* System Size */}
          <div className="pdf-grid-item">
            <p className="pdf-label">Sistem Boyutu</p>
            <p className="pdf-value">{summaryData.systemSizeKW?.toFixed(2) ?? 'N/A'} kW</p>
          </div>
          {/* Annual Production */}
          <div className="pdf-grid-item">
            <p className="pdf-label">Yıllık Üretim</p>
            <p className="pdf-value">{summaryData.annualProductionKWh?.toLocaleString() ?? 'N/A'} kWh</p>
          </div>
          {/* Used Roof Area */}
          <div className="pdf-grid-item">
            <p className="pdf-label">Kullanılan Çatı Alanı</p>
            <p className="pdf-value">
              {formatNumber(summaryData.usedArea)} m² ({formatNumber(summaryData.roofPercentageUsed)}%)
            </p>
            <div className="pdf-progress-bar-bg">
              <div className="pdf-progress-bar" style={{width: `${summaryData.roofPercentageUsed ?? 0}%`}}></div>
            </div>
          </div>
          {/* Number of Panels */}
          <div className="pdf-grid-item">
            <p className="pdf-label">Panellerin Sayısı</p>
            <p className="pdf-value">{summaryData.numberOfPanels ?? 'N/A'}</p>
          </div>
          {/* Panel Type */}
          <div className="pdf-grid-item">
            <p className="pdf-label">Kullanılan Panel Tipi</p>
            <p className="pdf-value">
              {summaryData.panelType === 'premium' 
                ? '10BB Halfcut Bifacial 144 Cell, 625 W'
                : summaryData.panelType === 'highEfficiency'
                  ? '10BB Halfcut 144 Cell, 600 W'
                  : '10BB Halfcut 144 Cell, 560 W'}
            </p>
          </div>
          {/* Production Per Hour */}
          <div className="pdf-grid-item">
            <p className="pdf-label">Saatlik Üretim</p>
            <p className="pdf-value">{summaryData.productionPerHour?.toLocaleString() ?? 'N/A'} watt</p>
          </div>
        </div>
      </div>

      {/* Financial Details and Environmental Impact */}
      <div className="pdf-grid pdf-grid-2col">
        {/* Financial Details */}
        <div className="pdf-card">
          <h2 className="pdf-card-title"><DollarSign className="pdf-icon" /> Finansal Detaylar</h2>
          <div className="pdf-grid-item">
            <p className="pdf-label">Projenin Maliyeti</p>
            <p className="pdf-value">
              {summaryData.minProjectCost?.toLocaleString()} TL - {summaryData.maxProjectCost?.toLocaleString()} TL
            </p>
          </div>
          <div className="pdf-grid-item">
            <p className="pdf-label">Geri Ödeme Süresi</p>
            <p className="pdf-value">{summaryData.paybackPeriodYears?.toFixed(1) ?? 'N/A'} yıl</p>
            <div className="pdf-progress-bar-bg">
              <div className="pdf-progress-bar" style={{width: `${(summaryData.paybackPeriodYears ?? 0) / 25 * 100}%`}}></div>
            </div>
          </div>
          <div className="pdf-grid-item">
            <p className="pdf-label">Yaşam Boyu Tasarruf</p>
            <p className="pdf-value">{summaryData.lifetimeSavings?.toLocaleString() ?? 'N/A'} TL</p>
          </div>
        </div>

        {/* Environmental Impact */}
        <div className="pdf-card">
          <h2 className="pdf-card-title"><Leaf className="pdf-icon" /> Çevresel Etkiler</h2>
          <div className="pdf-grid-item">
            <p className="pdf-label">Karbondioksit Azaltımı</p>
            <p className="pdf-value">{summaryData.co2OffsetTons?.toFixed(2) ?? 'N/A'} tons</p>
          </div>
          <div className="pdf-grid-item">
            <p className="pdf-label">Kurtarılan Ağaç Sayısı</p>
            <p className="pdf-value">{((summaryData.co2OffsetTons ?? 0) * 16.5).toFixed(0)}</p>
            <div className="pdf-tree-icons">
              {[...Array(Math.min(10, Math.ceil((summaryData.co2OffsetTons ?? 0) * 16.5)))].map((_, i) => (
                <Leaf key={i} className="pdf-tree-icon" />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="pdf-charts-container">
        {/* First Chart: Assign 'first-chart' class */}
        <div className="pdf-chart-section first-chart">
          <h2 className="pdf-chart-title">Aylık Üretim Tahmini</h2>
          {monthlyChartImage ? (
            <img src={monthlyChartImage} alt="Aylık Üretim Tahmini" className="pdf-chart" />
          ) : (
            <p>Grafik yükleniyor...</p>
          )}
        </div>

        {/* Second Chart */}
        <div className="pdf-chart-section">
          <h2 className="pdf-chart-title">Enerji Üretim ve Satış Analizi</h2>
          {energyProductionChartImage ? (
            <img src={energyProductionChartImage} alt="Enerji Üretim ve Satış Analizi" className="pdf-chart" />
          ) : (
            <p>Grafik yükleniyor...</p>
          )}
        </div>
      </div>

      {/* Footer */}
      <div className="pdf-footer">
        <p>Bu rapor tahmini temel alınarak hazırlanmıştır ve gerçek kurulum ve enerji kullanımına göre değişebilir.</p>
        <p>Daha fazla bilgi için lütfen info@lumosol.com.tr - info@pvenerji.com adresine veya 0(850) 441 9078 numaralı telefon aracılığıyla bize ulaşın.</p>
        <p className="pdf-copyright">© {new Date().getFullYear()} Lumosol® & Pv Enerji®. Tüm hakları saklıdır. İzinsiz kopyalanması durumunda yasal işlem başlatılacaktır.</p>
      </div>
    </div>
  );
};

export default PDFContent;