import React, { useState } from 'react';
import { Menu, X, Phone, Mail, Facebook, Twitter, Instagram, Send } from 'lucide-react';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'sonner';
import { subscribeToNewsletter } from '../services/api';

interface MobileMenuProps {
  config: {
    contact_phone: string;
    contact_email: string;
    social_facebook: string;
    social_twitter: string;
    social_instagram: string;
  };
  onFooterLinkClick: (slug: string, e: React.MouseEvent) => Promise<void>;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ config, onFooterLinkClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [isSubscribing, setIsSubscribing] = useState(false);

  const handleNewsletterSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (!newsletterEmail) {
      toast.error('Lütfen bir e-posta adresi girin.');
      return;
    }

    setIsSubscribing(true);
    try {
      const response = await subscribeToNewsletter(newsletterEmail);
      
      if (response.status === 'success') {
        toast.success('Bülten aboneliğiniz başarıyla oluşturuldu!');
        setNewsletterEmail('');
      } else {
        toast.error(response.message || 'Bir hata oluştu.');
      }
    } catch (error) {
      console.error('Newsletter subscription error:', error);
      toast.error('Bülten aboneliği oluşturulurken bir hata oluştu.');
    } finally {
      setIsSubscribing(false);
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="fixed top-4 right-4 z-[1003] md:hidden"
        onClick={() => setIsOpen(true)}
      >
        <Menu className="h-5 w-5" />
      </Button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-background/80 backdrop-blur-sm z-[1003] md:hidden"
              onClick={() => setIsOpen(false)}
            />
            
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'spring', damping: 20 }}
              className="fixed right-0 top-0 bottom-0 w-[280px] bg-background border-l border-border z-[1004] md:hidden overflow-y-auto"
            >
              <div className="p-4">
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute top-4 left-4"
                  onClick={() => setIsOpen(false)}
                >
                  <X className="h-5 w-5" />
                </Button>

                <div className="space-y-6 mt-12">
                  <div className="space-y-2">
                    <h3 className="font-semibold">İletişim</h3>
                    <div className="space-y-2 text-sm text-muted-foreground">
                      <a href={`tel:${config.contact_phone}`} className="flex items-center gap-2">
                        <Phone className="h-4 w-4" />
                        {config.contact_phone}
                      </a>
                      <a href={`mailto:${config.contact_email}`} className="flex items-center gap-2">
                        <Mail className="h-4 w-4" />
                        {config.contact_email}
                      </a>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold">Bağlantılar</h3>
                    <div className="space-y-2 text-sm">
                      <a 
                        href="/about"
                        onClick={(e) => onFooterLinkClick('about', e)}
                        className="block text-muted-foreground hover:text-primary"
                      >
                        Hakkımızda
                      </a>
                      <a 
                        href="/contact"
                        onClick={(e) => onFooterLinkClick('contact', e)}
                        className="block text-muted-foreground hover:text-primary"
                      >
                        İletişim
                      </a>
                      <a 
                        href="/blog"
                        onClick={(e) => onFooterLinkClick('blog', e)}
                        className="block text-muted-foreground hover:text-primary"
                      >
                        Blog
                      </a>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold">Yasal</h3>
                    <div className="space-y-2 text-sm">
                      <a 
                        href="/kullanim-kosullari"
                        onClick={(e) => onFooterLinkClick('kullanim-kosullari', e)}
                        className="block text-muted-foreground hover:text-primary"
                      >
                        Kullanım Koşulları
                      </a>
                      <a 
                        href="/kvkk"
                        onClick={(e) => onFooterLinkClick('kvkk', e)}
                        className="block text-muted-foreground hover:text-primary"
                      >
                        KVKK
                      </a>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold">Sosyal Medya</h3>
                    <div className="flex gap-4">
                      <a href={config.social_facebook} target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-primary">
                        <Facebook className="h-5 w-5" />
                      </a>
                      <a href={config.social_twitter} target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-primary">
                        <Twitter className="h-5 w-5" />
                      </a>
                      <a href={config.social_instagram} target="_blank" rel="noopener noreferrer" className="text-muted-foreground hover:text-primary">
                        <Instagram className="h-5 w-5" />
                      </a>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h3 className="font-semibold">Bülten</h3>
                    <div className="flex space-x-1">
                      <Input 
                        type="email" 
                        placeholder="E-posta" 
                        className="h-8 text-xs"
                        value={newsletterEmail}
                        onChange={(e) => setNewsletterEmail(e.target.value)}
                        disabled={isSubscribing}
                      />
                      <Button 
                        variant="outline" 
                        size="icon" 
                        className="h-8 w-8"
                        onClick={handleNewsletterSubmit}
                        disabled={isSubscribing}
                      >
                        {isSubscribing ? (
                          <span className="animate-spin">⌛</span>
                        ) : (
                          <Send className="w-3 h-3" />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="mt-8 pt-4 border-t border-border">
                  <p className="text-xs text-muted-foreground text-center">
                    Copyright © {new Date().getFullYear()}. Tüm hakları saklıdır.
                  </p>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu; 