import React, { useState, useEffect } from 'react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Label } from './ui/label';
import { getSimulationSetting, updateSimulationSetting } from '../services/api';

interface SimulationSetting {
  name: string;
  value: string;
  description: string;
}

const SimulationSettings: React.FC = () => {
  const [settings, setSettings] = useState<SimulationSetting[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await getSimulationSetting('all');
      if (response && response.status === 'success') {
        setSettings(response.settings);
      } else {
        throw new Error(response?.message || 'Failed to fetch simulation settings');
      }
    } catch (error) {
      console.error('Error fetching simulation settings:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleSettingChange = (index: number, value: string) => {
    const newSettings = [...settings];
    newSettings[index].value = value;
    setSettings(newSettings);
  };

  const saveSettings = async () => {
    try {
      for (const setting of settings) {
        const response = await updateSimulationSetting(setting.name, setting.value);
        if (response.status !== 'success') {
          throw new Error(`Failed to update setting ${setting.name}: ${response.message}`);
        }
      }
      alert('Settings updated successfully');
    } catch (error) {
      console.error('Error saving simulation settings:', error);
      alert(error instanceof Error ? error.message : 'Failed to update settings');
    }
  };

  if (loading) return <div>Ayarlar Yükleniyor...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Simülasyon Ayarları</h2>
      {settings.map((setting, index) => (
        <div key={setting.name} className="mb-4">
          <Label htmlFor={setting.name} className="block text-sm font-medium text-gray-700">
            {setting.name}
          </Label>
          <Input
            id={setting.name}
            type="number"
            value={setting.value}
            onChange={(e) => handleSettingChange(index, e.target.value)}
            className="mt-1"
            step="any"
          />
          <p className="mt-1 text-sm text-gray-500">{setting.description}</p>
        </div>
      ))}
      <Button onClick={saveSettings}>Ayarları Kaydet</Button>
    </div>
  );
};

export default SimulationSettings;