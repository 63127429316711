import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Label } from './ui/label';
import { Trash2Icon, PlusCircleIcon } from 'lucide-react';
import { getPanels, updatePanel, createPanel, deletePanel } from '../services/api';

interface PanelType {
  id?: number;
  type: string;
  name: string;
  wattage: number;
  efficiency: number;
  cost_multiplier: number;
  image_url: string;
  description: string;
  active: boolean;
}

const PanelManagement: React.FC = () => {
  const [panels, setPanels] = useState<PanelType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPanels();
  }, []);

  const fetchPanels = async () => {
    try {
      const response = await getPanels();
      if (response.status === 'success') {
        setPanels(response.panels);
      }
    } catch (error) {
      setError('Failed to fetch panels');
    } finally {
      setLoading(false);
    }
  };

  const handleAddPanel = () => {
    setPanels([...panels, {
      type: '',
      name: '',
      wattage: 0,
      efficiency: 0,
      cost_multiplier: 1,
      image_url: '',
      description: '',
      active: true
    }]);
  };

  const handleUpdatePanel = async (index: number, field: keyof PanelType, value: any) => {
    const updatedPanels = [...panels];
    updatedPanels[index] = { ...updatedPanels[index], [field]: value };
    setPanels(updatedPanels);
  };

  const handleSavePanel = async (panel: PanelType) => {
    try {
      const panelData = {
        id: panel.id,
        type: panel.type,
        name: panel.name,
        wattage: parseFloat(panel.wattage.toString()),
        efficiency: parseFloat(panel.efficiency.toString()),
        cost_multiplier: parseFloat(panel.cost_multiplier.toString()),
        image_url: panel.image_url,
        description: panel.description
      };

      let response;
      if (panel.id) {
        response = await updatePanel(panelData);
      } else {
        response = await createPanel(panelData);
      }

      if (response.status === 'success') {
        await fetchPanels(); // Refresh the panel list
        setError(null);
      } else {
        setError(response.message || 'Failed to save panel');
      }
    } catch (error) {
      console.error('Save panel error:', error);
      setError('Failed to save panel');
    }
  };

  const handleDeletePanel = async (id: number) => {
    try {
      await deletePanel(id);
      await fetchPanels();
    } catch (error) {
      setError('Failed to delete panel');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Panel Yönetimi</h2>
        <Button onClick={handleAddPanel}>
          <PlusCircleIcon className="mr-2 h-4 w-4" />
          Yeni Panel Ekle
        </Button>
      </div>

      <div className="grid gap-4 md:grid-cols-2">
        {panels.map((panel, index) => (
          <Card key={panel.id || index}>
            <CardHeader>
              <CardTitle>{panel.name || 'Yeni Panel'}</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor={`name-${index}`}>Panel Adı</Label>
                <Input
                  id={`name-${index}`}
                  value={panel.name}
                  onChange={(e) => handleUpdatePanel(index, 'name', e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor={`type-${index}`}>Panel Tipi</Label>
                <Input
                  id={`type-${index}`}
                  value={panel.type}
                  onChange={(e) => handleUpdatePanel(index, 'type', e.target.value)}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor={`wattage-${index}`}>Watt</Label>
                  <Input
                    id={`wattage-${index}`}
                    type="number"
                    value={panel.wattage}
                    onChange={(e) => handleUpdatePanel(index, 'wattage', Number(e.target.value))}
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor={`efficiency-${index}`}>Verimlilik</Label>
                  <Input
                    id={`efficiency-${index}`}
                    type="number"
                    step="0.01"
                    value={panel.efficiency}
                    onChange={(e) => handleUpdatePanel(index, 'efficiency', Number(e.target.value))}
                  />
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor={`cost-${index}`}>Maliyet Çarpanı</Label>
                <Input
                  id={`cost-${index}`}
                  type="number"
                  step="0.01"
                  value={panel.cost_multiplier}
                  onChange={(e) => handleUpdatePanel(index, 'cost_multiplier', Number(e.target.value))}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor={`image-${index}`}>Resim URL</Label>
                <Input
                  id={`image-${index}`}
                  value={panel.image_url}
                  onChange={(e) => handleUpdatePanel(index, 'image_url', e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor={`description-${index}`}>Açıklama</Label>
                <Textarea
                  id={`description-${index}`}
                  value={panel.description}
                  onChange={(e) => handleUpdatePanel(index, 'description', e.target.value)}
                />
              </div>

              <div className="flex justify-between items-center pt-4">
                <Button
                  variant="destructive"
                  onClick={() => panel.id && handleDeletePanel(panel.id)}
                >
                  <Trash2Icon className="mr-2 h-4 w-4" />
                  Sil
                </Button>
                <Button onClick={() => handleSavePanel(panel)}>
                  Kaydet
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default PanelManagement;