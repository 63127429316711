import React from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const hideNavigation = ['/summary', '/quote'].some(path => location.pathname.startsWith(path));

  return (
    <>
      {!hideNavigation && <Navigation />}
      {children}
    </>
  );
};

export default Layout;
