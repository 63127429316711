import React, { useState, useEffect } from 'react';
import { getQuotes, updateQuoteStatus, deleteQuoteHistory, exportQuotesToExcel } from '../services/api';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from './ui/card';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from './ui/Table';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Button } from './ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import * as XLSX from 'xlsx';

interface GroupedQuotes {
  [rooftopId: string]: {
    quotes: any[];
    isExpanded: boolean;
  };
}

const QuoteManagement: React.FC = () => {
  const [quotes, setQuotes] = useState<any[]>([]);
  const [groupedQuotes, setGroupedQuotes] = useState<GroupedQuotes>({});
  const [rooftopsWithoutQuotes, setRooftopsWithoutQuotes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getQuotes();
        if (response.status === 'success' && response.data) {
          setQuotes(response.data.quotes);
          setRooftopsWithoutQuotes(response.data.rooftopsWithoutQuotes);
          
          // Group quotes by rooftop_id
          const grouped = response.data.quotes.reduce((acc: GroupedQuotes, quote: any) => {
            const rooftopId = quote.rooftop_id.toString();
            if (!acc[rooftopId]) {
              acc[rooftopId] = {
                quotes: [],
                isExpanded: false
              };
            }
            acc[rooftopId].quotes.push(quote);
            // Sort quotes by created_at date in descending order
            acc[rooftopId].quotes.sort((a, b) => 
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );
            return acc;
          }, {});
          setGroupedQuotes(grouped);
        } else {
          throw new Error(response.message || 'Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error instanceof Error ? error.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleQuoteGroup = (rooftopId: string) => {
    setGroupedQuotes(prev => ({
      ...prev,
      [rooftopId]: {
        ...prev[rooftopId],
        isExpanded: !prev[rooftopId].isExpanded
      }
    }));
  };

  const renderQuoteRow = (quote: any, isLatest: boolean = false) => (
    <Tr key={quote.id} className={isLatest ? 'bg-muted/50' : 'bg-background'}>
      <Td>{quote.rooftop_id}</Td>
      <Td>{`${quote.first_name} ${quote.last_name}`}</Td>
      <Td>{quote.email}</Td>
      <Td>{quote.phone}</Td>
      <Td>{quote.system_size_kw} kW</Td>
      <Td>{quote.annual_production_kwh} kWh</Td>
      <Td>{quote.estimated_cost} TL</Td>
      <Td>
        <select
          value={quote.status}
          onChange={(e) => handleStatusChange(quote.id, e.target.value)}
          className="p-2 border rounded bg-background"
        >
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
          <option value="in_progress">In Progress</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </Td>
      <Td>{new Date(quote.created_at).toLocaleString()}</Td>
    </Tr>
  );

  const handleStatusChange = async (quoteId: number, newStatus: string) => {
    try {
      const response = await updateQuoteStatus(quoteId, newStatus);
      if (response.status === 'success') {
        // Update quotes state
        setQuotes(quotes.map(quote => 
          quote.id === quoteId ? { ...quote, status: newStatus } : quote
        ));

        // Update groupedQuotes state
        setGroupedQuotes(prev => {
          const newGroupedQuotes = { ...prev };
          Object.keys(newGroupedQuotes).forEach(rooftopId => {
            newGroupedQuotes[rooftopId].quotes = newGroupedQuotes[rooftopId].quotes.map(quote =>
              quote.id === quoteId ? { ...quote, status: newStatus } : quote
            );
          });
          return newGroupedQuotes;
        });
      } else {
        throw new Error(response.message || 'Failed to update quote status');
      }
    } catch (error) {
      console.error('Error updating quote status:', error);
      alert('Failed to update quote status: ' + (error instanceof Error ? error.message : 'Unknown error'));
    }
  };

  const handleDeleteHistory = async () => {
    try {
      const response = await deleteQuoteHistory();
      if (response.status === 'success') {
        setQuotes([]);
        setRooftopsWithoutQuotes([]);
        setIsDeleteDialogOpen(false);
      } else {
        throw new Error(response.message || 'Failed to delete quote history');
      }
    } catch (error) {
      console.error('Error deleting quote history:', error);
      alert('Failed to delete quote history');
    }
  };

  const handleExportExcel = async () => {
    try {
      const response = await exportQuotesToExcel();
      
      if (response.status === 'success' && response.data) {
        // Excel dosyası oluştur
        const wb = XLSX.utils.book_new();
        
        // Başlıkları Türkçe yap ve sütun genişliklerini ayarla
        const quotesWS = XLSX.utils.json_to_sheet(response.data.quotes, {
          header: [
            'Teklif ID', 'Çatı ID', 'Ad', 'Soyad', 'Email', 'Telefon',
            'Sistem Büyüklüğü (kW)', 'Yıllık Üretim (kWh)', 
            'Tahmini Maliyet (TL)', 'Durum', 'Oluşturulma Tarihi'
          ]
        });
        
        // Sütun genişliklerini ayarla
        const colWidths = [
          { wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 15 },
          { wch: 25 }, { wch: 15 }, { wch: 20 }, { wch: 20 },
          { wch: 20 }, { wch: 15 }, { wch: 20 }
        ];
        quotesWS['!cols'] = colWidths;
        
        XLSX.utils.book_append_sheet(wb, quotesWS, "Teklifler");
        
        // Teklifsiz Çatılar sayfası
        const rooftopsWS = XLSX.utils.json_to_sheet(response.data.rooftopsWithoutQuotes);
        XLSX.utils.book_append_sheet(wb, rooftopsWS, "Teklifsiz Çatılar");
        
        // Excel dosyasını indir
        XLSX.writeFile(wb, `solar_quotes_${new Date().toISOString().split('T')[0]}.xlsx`);
      } else {
        throw new Error(response.message || 'Export failed');
      }
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      alert('Excel export failed: ' + (error instanceof Error ? error.message : 'Unknown error'));
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Quote Management</h1>
        <div className="space-x-2">
          <Button onClick={handleExportExcel} variant="outline">
            Export to Excel
          </Button>
          <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="destructive">Delete History</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogDescription>
                  This action will delete all quotes, rooftops, and simulations. This action cannot be undone.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter>
                <Button variant="outline" onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                <Button variant="destructive" onClick={handleDeleteHistory}>Delete</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <Tabs defaultValue="quotes" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="quotes">
            Active Quotes ({Object.keys(groupedQuotes).length})
          </TabsTrigger>
          <TabsTrigger value="rooftops">
            Rooftops Without Quotes ({rooftopsWithoutQuotes.length})
          </TabsTrigger>
        </TabsList>

        <TabsContent value="quotes">
          <Card>
            <CardHeader>
              <CardTitle>Active Quotes</CardTitle>
              <CardDescription>
                Manage and track all active quote requests. Click on a row to see historical quotes for the same rooftop.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="overflow-x-auto">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Rooftop ID</Th>
                      <Th>Name</Th>
                      <Th>Email</Th>
                      <Th>Phone</Th>
                      <Th>System Size</Th>
                      <Th>Annual Production</Th>
                      <Th>Estimated Cost</Th>
                      <Th>Status</Th>
                      <Th>Created At</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(groupedQuotes).map(([rooftopId, group]) => (
                      <React.Fragment key={rooftopId}>
                        <Tr 
                          className="cursor-pointer hover:bg-muted/50"
                          onClick={() => toggleQuoteGroup(rooftopId)}
                        >
                          <Td colSpan={9} className="py-2">
                            <div className="flex items-center space-x-2">
                              {group.isExpanded ? (
                                <ChevronUpIcon className="w-4 h-4" />
                              ) : (
                                <ChevronDownIcon className="w-4 h-4" />
                              )}
                              <span className="font-medium">
                                Rooftop #{rooftopId} - {group.quotes.length} quote(s)
                              </span>
                            </div>
                          </Td>
                        </Tr>
                        {group.isExpanded ? (
                          group.quotes.map((quote, index) => renderQuoteRow(quote))
                        ) : (
                          renderQuoteRow(group.quotes[group.quotes.length - 1], true)
                        )}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="rooftops">
          <Card>
            <CardHeader>
              <CardTitle>Rooftops Without Quotes</CardTitle>
              <CardDescription>View all rooftops that haven't been quoted yet</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="overflow-x-auto">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Area</Th>
                      <Th>Panel Type</Th>
                      <Th>Location</Th>
                      <Th>System Size</Th>
                      <Th>Annual Production</Th>
                      <Th>Project Cost</Th>
                      <Th>Created At</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {rooftopsWithoutQuotes.map((rooftop) => (
                      <Tr key={rooftop.id}>
                        <Td>{rooftop.id}</Td>
                        <Td>{rooftop.area} m²</Td>
                        <Td>{rooftop.panel_type}</Td>
                        <Td>{rooftop.location}</Td>
                        <Td>{rooftop.system_size_kw} kW</Td>
                        <Td>{rooftop.annual_production_kwh} kWh</Td>
                        <Td>{rooftop.project_cost} TL</Td>
                        <Td>{new Date(rooftop.created_at).toLocaleString()}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default QuoteManagement;