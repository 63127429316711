import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getVisitorData, deleteVisitorHistory } from '../services/api';
import { Button } from './ui/button';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from './ui/dialog';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const UserAnalytics: React.FC = () => {
  const [visitorData, setVisitorData] = useState<any[]>([]);
  const [detailedVisitorData, setDetailedVisitorData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    fetchVisitorData();
  }, []);

  const fetchVisitorData = async () => {
    try {
      setIsLoading(true);
      const response = await getVisitorData();
      console.log('API response:', response);
      if (response.status === 'success') {
        setVisitorData(response.visitorData || []);
        setDetailedVisitorData(response.detailedVisitorData || []);
      } else {
        throw new Error(response.message || 'Failed to fetch visitor data');
      }
    } catch (error) {
      console.error('Error fetching visitor data:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteHistory = async () => {
    try {
      const response = await deleteVisitorHistory();
      if (response.status === 'success') {
        setVisitorData([]);
        setDetailedVisitorData([]);
        setIsDeleteDialogOpen(false);
        // Add a success message or notification here
      } else {
        throw new Error(response.message || 'Failed to delete visitor history');
      }
    } catch (error) {
      console.error('Error deleting visitor history:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
      // Add an error message or notification here
    }
  };

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Visitors'
        }
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Pageviews'
        }
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
    },
  };

  const chartData = {
    labels: visitorData.map(item => item.date),
    datasets: [
      {
        label: 'Visitors',
        data: visitorData.map(item => item.visitors),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Pageviews',
        data: visitorData.map(item => item.pageviews),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y1',
      },
    ],
  };

  if (isLoading) {
    return <div>Loading visitor data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            <span>Visitor Analytics</span>
            <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
              <DialogTrigger asChild>
                <Button variant="destructive">Delete History</Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Are you sure?</DialogTitle>
                  <DialogDescription>
                    This action will delete all visitor analytics data. This action cannot be undone.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button variant="outline" onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                  <Button variant="destructive" onClick={handleDeleteHistory}>Delete</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200">Visitor Analytics</h2>
          <div className="mb-8 w-full max-w-2xl mx-auto">
            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Daily Visitors and Pageviews</h3>
            <div className="h-64">
              <Line options={chartOptions} data={chartData} />
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Detailed Visitor Data</h3>
            {detailedVisitorData.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">IP</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Device</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Last Visit</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Total Visits</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Unique Pages</th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                  {detailedVisitorData.map((visitor) => (
                    <tr key={visitor.ip}>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">{visitor.ip}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">{visitor.device_type}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">{visitor.last_visit}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">{visitor.total_visits}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-800 dark:text-gray-200">{visitor.unique_pages}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-800 dark:text-gray-200">No detailed visitor data available.</p>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserAnalytics;