import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import MainPage from './pages/main';
import SummaryPage from './pages/SummaryPage';
import QuotePage from './pages/QuotePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AdminDashboard from './pages/AdminDashboard';
import UserProfilePage from './pages/UserProfilePage';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import { useState, useEffect } from 'react';
import { Toaster } from 'sonner';

const PageWrapper: React.FC<{ slug: string }> = ({ slug }) => {
  const [pageData, setPageData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}?action=get_page&slug=${slug}`);
        const data = await response.json();
        
        if (data.status === 'success') {
          if (data.redirect) {
            window.location.href = data.redirect_url;
            return;
          }
          setPageData(data.page);
        }
      } catch (error) {
        console.error('Error fetching page:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [slug]);

  if (loading) return <div>Yükleniyor...</div>;
  if (!pageData) return <div>Sayfa bulunamadı</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">{pageData.title}</h1>
      {pageData.content && (
        <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
      )}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/summary/:rooftopId" element={<SummaryPage />} />
              <Route path="/quote" element={<QuotePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:slug" element={<BlogPostPage />} />
              <Route path="/admin" element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              } />
              <Route path="/profile" element={
                <ProtectedRoute>
                  <UserProfilePage />
                </ProtectedRoute>
              } />
              <Route path="*" element={<PageWrapper slug={window.location.pathname.split('/').pop() || ''} />} />
            </Routes>
          </Layout>
        </Router>
        <Toaster position="top-right" />
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;