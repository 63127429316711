import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { Button } from "../../components/ui/button"
import { Card } from "../../components/ui/card"
import { Search, Pencil, Check, Sun, X, ArrowRight } from 'lucide-react'

interface TutorialStep {
  title: string;
  description: string;
  icon: React.ElementType;
  targetSelector: string;
  highlightSelector: string;
}

const tutorialSteps: TutorialStep[] = [
  {
    title: "Çatınızı Bulun",
    description: "Arama çubuğunu veya 'Beni Bul' düğmesini kullanarak haritada çatınızı bulun.",
    icon: Search,
    targetSelector: ".leaflet-container",
    highlightSelector: "button[data-tutorial='find-me-button']",
  },
  {
    title: "Çizim Başlatın",
    description: "Çizim için 'Çizim Başlat' düğmesine tıklayın.",
    icon: Pencil,
    targetSelector: ".leaflet-container",
    highlightSelector: "button[data-tutorial='start-drawing']",
  },
  {
    title: "Çatınızı Çizin",
    description: "Haritada çatınızı çevreleyen noktaları oluşturmak için haritayı tıklayın. Çizimi tamamlamak için çift tıklayın.",
    icon: Pencil,
    targetSelector: ".leaflet-container",
    highlightSelector: ".leaflet-container",
  },
  {
    title: "Seçimi Ayarlayın",
    description: "İhtiyacınız olursa çatınızın çevresini düzenlemek için düzenleme araçlarını kullanın.",
    icon: Check,
    targetSelector: ".leaflet-container",
    highlightSelector: "button[data-tutorial='finish-drawing']",
  },
  {
    title: "Ayarları Girin",
    description: "Güncel aylık elektrik maliyetinizi girin.",
    icon: Sun,
    targetSelector: "aside",
    highlightSelector: "input[placeholder='TL']",
  },
  {
    title: "Kapsamı Seçin",
    description: "Enerji ihtiyaçlarınıza göre tam veya kısmi çatı kapsamını seçin.",
    icon: Sun,
    targetSelector: "aside",
    highlightSelector: ".bg-black.bg-opacity-50",
  },
  {
    title: "Gözden Geçirin ve Devam Edin",
    description: "Seçimlerinizi gözden geçirin ve 'Devam Et' düğmesine tıklayın.",
    icon: ArrowRight,
    targetSelector: ".leaflet-container",
    highlightSelector: "button[data-tutorial='continue-button']",
  }
]

interface HighlightOverlayProps {
  selector: string;
  tutorialCardRef: React.RefObject<HTMLDivElement>;
}

const HighlightOverlay: React.FC<HighlightOverlayProps> = ({ selector, tutorialCardRef }) => {
  const [highlightPosition, setHighlightPosition] = useState({ top: 0, left: 0, width: 0, height: 0 })
  const [tutorialPosition, setTutorialPosition] = useState({ top: 0, left: 0, width: 0, height: 0 })

  useEffect(() => {
    const updatePositions = () => {
      const highlightElement = document.querySelector(selector)
      if (highlightElement) {
        const rect = highlightElement.getBoundingClientRect()
        setHighlightPosition({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        })
      }

      if (tutorialCardRef.current) {
        const rect = tutorialCardRef.current.getBoundingClientRect()
        setTutorialPosition({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        })
      }
    }

    updatePositions()
    window.addEventListener('resize', updatePositions)
    return () => window.removeEventListener('resize', updatePositions)
  }, [selector, tutorialCardRef])

  return (
    <svg
      className="fixed inset-0 z-[99998] pointer-events-none"
      width="100%"
      height="100%"
    >
      <defs>
        <mask id="mask">
          <rect width="100%" height="100%" fill="white" />
          {/* Hole for the highlighted element */}
          <rect
            x={highlightPosition.left}
            y={highlightPosition.top}
            width={highlightPosition.width}
            height={highlightPosition.height}
            fill="black"
          />
          {/* Hole for the tutorial card */}
          <rect
            x={tutorialPosition.left}
            y={tutorialPosition.top}
            width={tutorialPosition.width}
            height={tutorialPosition.height}
            fill="black"
          />
        </mask>
      </defs>
      <rect
        width="100%"
        height="100%"
        fill="rgba(0, 0, 0, 0.5)"
        mask="url(#mask)"
      />
      {/* Highlight border for the element */}
      <rect
        x={highlightPosition.left - 4}
        y={highlightPosition.top - 4}
        width={highlightPosition.width + 8}
        height={highlightPosition.height + 8}
        fill="none"
        stroke="white"
        strokeWidth="2"
        rx="4"
        ry="4"
      />
    </svg>
  )
}

interface EnhancedTutorialProps {
  onClose: () => void;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EnhancedTutorial: React.FC<EnhancedTutorialProps> = ({ onClose, setIsMobileMenuOpen }) => {
  const [step, setStep] = useState(0)
  const tutorialCardRef = useRef<HTMLDivElement>(null)

  const nextStep = () => {
    if (step < tutorialSteps.length - 1) {
      setStep(step + 1)
      if (step === 0) {
        setIsMobileMenuOpen(false)
      } else if (step === 3) {
        setIsMobileMenuOpen(true)
      }
    } else {
      onClose()
    }
  }

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const IconComponent = tutorialSteps[step].icon
  const currentStep = tutorialSteps[step]

  const getTutorialPosition = () => {
    if (window.innerWidth <= 768) { // Mobile view
      // Special positioning for the "Beni Bul" step
      if (step === 0) {
        return 'top-[20%] -translate-y-1/2'; // Move it higher up for the first step
      }
      return 'top-1/2 -translate-y-1/2'; // Default center position for other steps
    }
    
    return 'bottom-4'; // Default desktop position
  };

  return (
    <div className="fixed inset-0 z-[100000] pointer-events-none">
      <HighlightOverlay selector={currentStep.highlightSelector} tutorialCardRef={tutorialCardRef} />
      <div className={`absolute inset-x-0 flex justify-center ${getTutorialPosition()} pointer-events-none`}>
        <Card 
          ref={tutorialCardRef} 
          className="pointer-events-auto bg-background/90 backdrop-blur-md rounded-lg p-6 w-[90vw] md:w-96 shadow-2xl border border-primary/20 mx-4"
        >
          <Button
            variant="ghost"
            size="icon"
            className="absolute top-2 right-2 text-muted-foreground hover:text-foreground transition-colors duration-200"
            onClick={onClose}
            aria-label="Close tutorial"
          >
            <X className="h-4 w-4" />
          </Button>
          <motion.div
            key={step}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center mb-4">
              <div className="bg-primary/10 p-2 rounded-full mr-4">
                <IconComponent className="w-6 h-6 text-primary" />
              </div>
              <h3 className="text-xl font-semibold text-foreground">{currentStep.title}</h3>
            </div>
            <p className="text-muted-foreground mb-6">{currentStep.description}</p>
            <div className="flex justify-between items-center">
              <Button
                variant="outline"
                onClick={prevStep}
                disabled={step === 0}
                className="w-24"
              >
                Önceki
              </Button>
              <div className="flex space-x-1">
                {tutorialSteps.map((_, index) => (
                  <motion.div
                    key={index}
                    className={`w-2 h-2 rounded-full ${index === step ? 'bg-primary' : 'bg-muted'}`}
                    animate={{ scale: index === step ? 1.5 : 1 }}
                    transition={{ duration: 0.3 }}
                  />
                ))}
              </div>
              <Button
                onClick={nextStep}
                className="w-24"
              >
                {step === tutorialSteps.length - 1 ? 'Tamamla' : 'Sonraki'}
              </Button>
            </div>
          </motion.div>
        </Card>
      </div>
    </div>
  )
}

export default EnhancedTutorial