import { LatLngTuple } from 'leaflet';

export function convertToLatLngExpression(coords: number[][]): LatLngTuple[] {
  return coords.map(([lng, lat]) => [lat, lng] as LatLngTuple);
}

export const handleSearch = async (address: string) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
    );
    const data = await response.json();
    if (data && data[0]) {
      return {
        lat: parseFloat(data[0].lat),
        lng: parseFloat(data[0].lon)
      };
    }
    return null;
  } catch (error) {
    console.error('Error searching address:', error);
    return null;
  }
};

export const handleSuggestions = async (query: string) => {
  if (!query) return [];
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`
    );
    const data = await response.json();
    return data.map((item: any) => item.display_name).slice(0, 5);
  } catch (error) {
    console.error('Error getting suggestions:', error);
    return [];
  }
};

export const handleFindMe = () => {
  return new Promise<{ lat: number; lng: number } | null>((resolve) => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      resolve(null);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      (error) => {
        console.error('Error getting location:', error);
        resolve(null);
      }
    );
  });
};