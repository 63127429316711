import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { DownloadIcon, SendIcon, CarIcon, TreesIcon, FactoryIcon, ArrowLeftIcon } from 'lucide-react';
import { 
  Chart as ChartJS, 
  ArcElement, 
  Tooltip, 
  Legend, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  LineElement, 
  PointElement, 
  ChartOptions, 
  ChartData 
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import PDFContent from '../components/PDFContent';
import { renderToStaticMarkup } from 'react-dom/server';
import { getRooftopSummary } from '../services/api';
import { MapContainer, TileLayer, Polygon, useMap } from 'react-leaflet';
import { LatLngExpression, LatLngBounds, LatLngTuple } from 'leaflet';
import { convertToLatLngExpression } from '../utils/mapUtils';
import Spinner from '../components/ui/Spinner';
import '../styles/pdfStyles.css';
import html2pdf from 'html2pdf.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title
);

export interface SummaryData {
  minProjectCost: number;
  maxProjectCost: number;
  annualProductionKWh: number;
  numberOfPanels: number;
  productionPerHour: number;
  area: number;
  roofArea: number;
  usedArea: number;
  roofPercentageUsed: number;
  paybackPeriodYears: number;
  lifetimeProduction: number;
  lifetimeSavings: number;
  yearlyRevenue: number;
  selfConsumptionSavings: number;
  gridSaleIncome: number;
  image_path: string;
  coordinates: number[][];
  co2OffsetTons: number;
  monthlyProductionKWh: Array<{
    month: string;
    production: number;
  }>;
  systemSizeKW: number;
  panelType: 'standard' | 'highEfficiency' | 'premium';
}

const SummaryPage: React.FC = () => {
  const { rooftopId } = useParams<{ rooftopId: string }>();
  const navigate = useNavigate();
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  
  // Update refs to point to Chart instances
  const monthlyChartRef = useRef<ChartJS<'bar', number[], string> | null>(null);
  const energyProductionChartRef = useRef<ChartJS<'bar', number[], string> | null>(null);

  const fetchSummaryData = useCallback(async () => {
    if (!isLoading) return;

    try {
      const result = await getRooftopSummary(parseInt(rooftopId!));
      console.log('Received summary data:', result);
      if (result.status === 'success' && result.data) {
        const processedData = {
          ...result.data,
          usedArea: result.data.usedArea || (result.data.area * (result.data.roofPercentageUsed / 100)),
          roofPercentageUsed: result.data.roofPercentageUsed || 0
        };
        setSummaryData(processedData);
      } else {
        throw new Error(result.message || 'Failed to fetch summary data');
      }
    } catch (error) {
      console.error('Error fetching summary data:', error);
      if (error instanceof Error) {
        alert(`An error occurred: ${error.message}`);
      } else {
        alert('An unknown error occurred. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [rooftopId, isLoading]);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  // Logging image paths
  useEffect(() => {
    if (summaryData) {
      console.log('Image path:', summaryData.image_path);
      console.log('Full image URL:', `${process.env.REACT_APP_API_BASE_URL}?action=getImage&filename=${summaryData.image_path}`);
    }
  }, [summaryData]);

  const handleDownloadPDF = async () => {
    if (!summaryData) {
      alert('No data available to generate PDF.');
      return;
    }

    setIsPdfLoading(true);

    try {
      // Capture chart images
      const monthlyChartImage = monthlyChartRef.current?.toBase64Image();
      const energyProductionChartImage = energyProductionChartRef.current?.toBase64Image();

      // Create a temporary container for PDF content
      const pdfContainer = document.createElement('div');

      // Render PDFContent into this container
      const pdfContentMarkup = renderToStaticMarkup(
        <PDFContent 
          summaryData={summaryData} 
          monthlyChartImage={monthlyChartImage || ''}
          energyProductionChartImage={energyProductionChartImage || ''}
        />
      );
      pdfContainer.innerHTML = pdfContentMarkup;

      // Convert the DOM element to PDF using html2pdf.js
      await html2pdf().set({
        margin:       10,                     // 10mm margin
        filename:     'report.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }).from(pdfContainer).save();
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('An error occurred while generating the PDF. Please try again.');
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleRequestQuote = () => {
    navigate('/quote', { state: { rooftopId } });
  };

  const calculateCenter = (coordinates: number[][]): LatLngExpression => {
    if (coordinates.length === 0) return [0, 0];
    const sumLng = coordinates.reduce((sum, coord) => sum + coord[0], 0);
    const sumLat = coordinates.reduce((sum, coord) => sum + coord[1], 0);
    return [sumLat / coordinates.length, sumLng / coordinates.length];
  };

  const FitBounds = ({ bounds }: { bounds: LatLngBounds }) => {
    const map = useMap();
    useEffect(() => {
      map.fitBounds(bounds);
    }, [map, bounds]);
    return null;
  };

  // Refactored generateMonthlyChartData function to accept SummaryData as parameter
  const generateMonthlyChartData = (data: SummaryData): ChartData<'bar', number[], string> => {
    return {
      labels: data.monthlyProductionKWh.map(item => item.month),
      datasets: [{
        label: 'Aylık Üretim',
        data: data.monthlyProductionKWh.map(item => item.production),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };
  };

  // Refactored generateEnergyProductionData function to accept SummaryData as parameter
  const generateEnergyProductionData = (data: SummaryData): ChartData<'bar', number[], string> => {
    return {
      labels: data.monthlyProductionKWh.map(item => item.month),
      datasets: [
        {
          label: 'Satılabilir Enerji (kWh)',
          data: data.monthlyProductionKWh.map(item => item.production * 0.85),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          yAxisID: 'y',
        },
        {
          label: 'Satış Tutarı (TL)',
          data: data.monthlyProductionKWh.map(item => item.production * 0.85 * 4.5),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          yAxisID: 'y1',
        }
      ]
    };
  };

  // Updated monthlyChartOptions with specific type literals
  const monthlyChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        beginAtZero: true,
        title: {
          display: true,
          text: 'Üretim (kWh)'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  // Updated energyProductionOptions with specific type literals
  const energyProductionOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Satılabilir Enerji (kWh)'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Satış Tutarı (TL)'
        },
        grid: {
          drawOnChartArea: false
        }
      }
    },
    plugins: {
      legend: {
        display: true
      }
    }
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (!summaryData) {
    return <div className="flex justify-center items-center h-screen">No data available</div>;
  }

  const center = calculateCenter(summaryData.coordinates);
  const polygonPositions = convertToLatLngExpression(summaryData.coordinates);
  const bounds = new LatLngBounds(polygonPositions as LatLngTuple[]);

  return (
    <div className="summary-page-container bg-background text-foreground">
      <header className="bg-card p-4 shadow">
        <div className="mx-auto max-w-7xl flex items-center justify-between">
          <h1 className="text-2xl font-bold">Güneş Enerjisi Proje Özeti</h1>
          <Button variant="ghost" size="icon" asChild>
            <Link to="/">
              <ArrowLeftIcon className="h-6 w-6" />
              <span className="sr-only">Ana sayfaya dön</span>
            </Link>
          </Button>
        </div>
      </header>
      <main className="summary-page-main flex-1 bg-background">
        <div className="container mx-auto px-4 py-8">
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            <Card className="lg:col-span-2">
              <CardHeader>
                <CardTitle>Proje Genel Bakış</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4 sm:grid-cols-2">
                  <div>
                    <h3 className="font-semibold mb-4">Sistem Detayları</h3>
                    <dl className="space-y-3 text-sm">
                      <div className="flex justify-between">
                        <dt>Sistem Boyutu:</dt>
                        <dd>{summaryData.systemSizeKW?.toFixed(2) ?? 'N/A'} kW</dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Panel Tipi:</dt>
                        <dd>
                          {summaryData.panelType === 'premium' 
                            ? '10BB Halfcut Bifacial 144 Cell, 625 W'
                            : summaryData.panelType === 'highEfficiency'
                              ? '10BB Halfcut 144 Cell, 600 W'
                              : '10BB Halfcut 144 Cell, 560 W'}
                        </dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Panel Sayısı:</dt>
                        <dd>{summaryData.numberOfPanels ?? 'N/A'}</dd>
                      </div>
                    </dl>

                    <h3 className="font-semibold mt-6 mb-4">Üretim Kapasitesi</h3>
                    <dl className="space-y-3 text-sm">
                      <div className="flex justify-between">
                        <dt>Yıllık Üretim:</dt>
                        <dd>{summaryData.annualProductionKWh?.toLocaleString() ?? 'N/A'} kWh/yıl</dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Günlük Üretim:</dt>
                        <dd>{(summaryData.annualProductionKWh / 365)?.toFixed(2) ?? 'N/A'} kWh/gün</dd>
                      </div>
                      <div className="flex justify-between">
                        <dt>Saatlik Üretim:</dt>
                        <dd>{summaryData.productionPerHour?.toLocaleString() ?? 'N/A'} watt</dd>
                      </div>
                    </dl>

                    <h3 className="font-semibold mt-6 mb-4">Alan Kullanımı</h3>
                    <dl className="space-y-3 text-sm">
                      <div className="flex justify-between">
                        <dt>Toplam Çatı Alanı:</dt>
                        <dd>{summaryData.roofArea?.toFixed(2) ?? 'N/A'} m²</dd>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <div className="flex justify-between">
                          <dt>Kullanılan Çatı Alanı:</dt>
                          <dd>{summaryData.usedArea?.toFixed(2) ?? 'N/A'} m² ({summaryData.roofPercentageUsed?.toFixed(2)}%)</dd>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div 
                            className="bg-primary h-2.5 rounded-full transition-all duration-300" 
                            style={{width: `${summaryData.roofPercentageUsed ?? 0}%`}}
                          />
                        </div>
                      </div>
                    </dl>
                  </div>

                  <div>
                    <h3 className="font-semibold mb-4">Finansal Bilgiler</h3>
                    <dl className="space-y-3 text-sm">
                      <div className="flex justify-between">
                        <dt>Proje Maliyeti:</dt>
                        <dd>{summaryData.minProjectCost?.toLocaleString()} TL - {summaryData.maxProjectCost?.toLocaleString()} TL</dd>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <div className="flex justify-between">
                          <dt>Geri Ödeme Süresi:</dt>
                          <dd>{summaryData.paybackPeriodYears ?? 'N/A'} yıl</dd>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div 
                            className="bg-green-500 h-2.5 rounded-full transition-all duration-300" 
                            style={{width: `${(summaryData.paybackPeriodYears ?? 0) / 25 * 100}%`}}
                          />
                        </div>
                      </div>
                    </dl>

                    <h3 className="font-semibold mt-6 mb-4">Çevresel Etki</h3>
                    <div className="space-y-4">
                      <div className="flex items-center space-x-3 bg-blue-50 p-3 rounded-lg">
                        <CarIcon className="h-5 w-5 text-blue-500" />
                        <span className="text-sm">{((summaryData.co2OffsetTons ?? 0) * 2471).toFixed(0)} km seyahat mesafesi telafisi</span>
                      </div>
                      <div className="flex items-center space-x-3 bg-blue-50 p-3 rounded-lg">
                        <FactoryIcon className="h-5 w-5 text-blue-500" />
                        <span className="text-sm">{((summaryData.co2OffsetTons ?? 0) * 1000).toFixed(0)} kg/yıl CO2 emisyonu azaltıldı</span>
                      </div>
                      <div className="flex items-center space-x-3 bg-green-50 p-3 rounded-lg">
                        <TreesIcon className="h-5 w-5 text-green-500" />
                        <span className="text-sm">{((summaryData.co2OffsetTons ?? 0) * 16.5).toFixed(0)} ağaç dikimine eşdeğer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Seçilen Alan</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="aspect-video w-full bg-gray-200 relative overflow-hidden">
                  <MapContainer
                    center={center}
                    zoom={18}
                    style={{ height: '300px', width: '100%' }}
                    zoomControl={false} // Zoom kontrolünü devre dışı bırak
                  >
                    <TileLayer
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                      attribution='&copy; <a href="https://www.esri.com/en-us/home">Esri</a>'
                    />
                    <Polygon
                      positions={polygonPositions}
                      pathOptions={{ color: '#3388ff', weight: 2, opacity: 0.7, fillOpacity: 0.2 }}
                    />
                    <FitBounds bounds={bounds} />
                  </MapContainer>
                </div>
                <p className="mt-2 text-sm">
                  <span className="font-medium">Koordinatlar:</span>
                  <br />
                  {summaryData.coordinates
                    ? summaryData.coordinates.map((coord, index) => (
                        <span key={index}>
                          [{coord[0].toFixed(6)}, {coord[1].toFixed(6)}]
                          {index < summaryData.coordinates.length - 1 ? ', ' : ''}
                        </span>
                      ))
                    : 'N/A'}
                </p>
              </CardContent>
            </Card>
          </div>

          <div className="grid gap-6 mt-6">
            <Card>
              <CardHeader>
                <CardTitle>Aylık Üretim Tahmini</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-[400px]">
                  <Bar 
                    ref={monthlyChartRef} 
                    data={generateMonthlyChartData(summaryData)} 
                    options={monthlyChartOptions}
                  />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Enerji Üretim ve Satış Analizi</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-[400px]">
                  <Bar
                    ref={energyProductionChartRef}
                    data={generateEnergyProductionData(summaryData)}
                    options={energyProductionOptions}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </main>
      <footer className="summary-page-footer p-4">
        <div className="mx-auto flex max-w-7xl justify-between">
          <Button variant="outline" onClick={handleDownloadPDF} disabled={isPdfLoading}>
            {isPdfLoading ? (
              <>
                <Spinner className="mr-2 h-4 w-4" />
                PDF Oluşturuluyor...
              </>
            ) : (
              <>
                <DownloadIcon className="mr-2 h-4 w-4" />
                PDF İndir
              </>
            )}
          </Button>
          <Button onClick={handleRequestQuote}>
            <SendIcon className="mr-2 h-4 w-4" />
            Teklif İste
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default SummaryPage;